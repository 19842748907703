import React from 'react';
import '../../../src/Style.scss'
import { AboutInnerBanner } from '../../Components/Common/InnerBanner/AboutInnerBanner';
import AboutInnBanContent from '../../Components/Common/InnerBanner/AboutInnBanContent';
import AboutContent from '../../Components/Common/AboutContent/AboutContent';
import AboutInfo from '../../Components/Common/AboutContent/AboutInfo';
import Transition from '../../Components/Transition';

const Introduction = () => {

  const { bg, doctor, title, data } = AboutInfo.intruduction;
  
  return (
    <>
      <AboutInnerBanner 
        image={AboutInnBanContent.intruduction.image}
        heading={AboutInnBanContent.intruduction.heading}
        pageName={AboutInnBanContent.intruduction.pageName}
        />

      <AboutContent 
        bg= {bg}
        doctor= {doctor}
        title ={title}
        data ={data}
      />
    </>
  )
}

export default Transition(Introduction) 