
const DbListData = {
    LaparoscopyHysteroscopy:{
        Indications:{
            dlHeading: "Indications of Laparoscopy",
            Dldata: [
                {
                    dlTxt: "Check for and possibly take out abnormal growths (such as tumors) in the belly or pelvis ex. fibroid uterus, ovarian cysts etc."
                },
                {
                    dlTxt: "Check for and treat conditions such as endometriosis, ectopic pregnancy, or pelvic inflammatory disease (PID)."
                },
                {
                    dlTxt: "Find conditions that can cause infertility. These conditions include cysts, adhesions, fibroids, and infection."
                },
                {
                    dlTxt: "Laparoscopy may be done after initial infertility tests do not show the cause for the infertility."
                },
                {
                    dlTxt: "Do a biopsy."
                },
                {
                    dlTxt: "See whether cancer in another area of the body has spread to the belly."
                },
                {
                    dlTxt: "To do a tubal ligation."
                },
                {
                    dlTxt: "Fix a hiatal hernia or an inguinal hernia."
                },
                {
                    dlTxt: "Take out organs, such as the uterus,(Hysterectomy), fallopian tubes and ovaries (salpingoophorectomy)."
                },
                {
                    dlTxt: "Find the cause of sudden or ongoing pelvic pain."
                },
            ]
        },
        Procedure:{
            pHeading: "Procedure",
            pData: [
                {
                    dlTxt: 'Laparoscopy is done by a gynecologist surgeon. General anesthesia is generally used, but other types of anesthesia, such as spinal anesthesia, may be used. About an hour before the surgery, you will empty your bladder. You will get fluids and medicine through an intravenous (IV) in a vein in your arm. You may get a medicine (sedative) to help you relax..'
                },
                {
                    dlTxt: 'Several procedures may be done after you get your anesthesia and are relaxed or asleep. 1. An airway will be placed in your throat to help you breathe if you get general anesthesia. 2. A thin flexible tube (urinary catheter) may be put through your urethra into the bladder. 3. Your surgical area will be washed with a special antiseptic solution.'
                },
                {
                    dlTxt: 'During laparoscopy, a small incision is made in the belly. More than one incision may be made if other tools will be used during the surgery. A hollow needle is put through the first incision and gas (carbon dioxide or nitrous oxide) is slowly put through the needle to inflate the belly. The gas lifts the abdominal wall away from the organs inside so your doctor can see clearly. A thin, lighted tube (laparoscope) is then put through the incision to look at the organs. Other tools can be used to take tissue samples, fix damage, or drain cysts. After the surgery, all the tools will be removed and the gas will be released. The incisions will be closed with stitches and covered with a bandage. The scar will be very small and will fade over time.'
                },
                {
                    dlTxt: 'Laparoscopy takes 30 to 90 minutes, depending on what is done, but can take longer if a condition (such as endometriosis) is found and treated. After the laparoscopy, you will go to the recovery room for 2 to 4 hours. You can usually do your normal activities the next day, but do not do any strenuous activity or exercise for about a week.'
                },
                
            ]
        }
        
    },

    WomenHealthCare:{
        dlHeading: "Today's lifestyle and its impact on woman's health",
            Dldata: [
                {
                    dlTxt: "Obesity and endocrine symptoms - irregular menstruation ,heavy cycles, premenstrual spotting,dysmenorrhea"
                },
                {
                    dlTxt: "Polycystic ovaries (irregular delayed menstruation, anovulatory cycles)"
                },
                {
                    dlTxt: "Precancerous lesions in female reproductive organs- uterus, ovaries, cervix, breast"
                },
                {
                    dlTxt: "Heart disease and stroke"
                },
                {
                    dlTxt: "Incontinence of urine"
                },
                {
                    dlTxt: "Amenorrhea (absent menstrual periods)"
                },
                {
                    dlTxt: "Dysmenorrhea (painful menstrual periods)"
                },
                {
                    dlTxt: "Infertility"
                },
                {
                    dlTxt: "Menorrhagia (heavy menstrual periods); a common indication for hysterectomy"
                },
                {
                    dlTxt: "Prolapse of pelvic organs"
                },
                {
                    dlTxt: "Infections of the vagina (vaginitis), cervix and uterus (including fungal)"
                },
                {
                    dlTxt: "Female reproductive organs are hormonally active structures. Apart from its reproductive functions any disturbance in structure or function affects the equilibrium of body."
                },
            ]
    },

    FertilityCounselling:{
        dlHeading: "Best fertility specialist in Mumbai",
        Dldata: [
            {
                dlTxt: "When first seeking assistance to become pregnant"
            },
            {
                dlTxt: "When wondering what could help"
            },
            {
                dlTxt: "When preparing to begin a particular treatment such as IVF"
            },
            {
                dlTxt: "When undecided as to what to do"
            },
            {
                dlTxt: "At times of particular crisis"
            },
            {
                dlTxt: "When the stress and strain feel too much"
            },
            {
                dlTxt: "If a pregnancy is not on-going"
            },
            {
                dlTxt: "If difficulties occur in relationships"
            },
            {
                dlTxt: "When looking at alternative ways to form a family or to stop treatment"
            },
            {
                dlTxt: "When seeking more information"
            },
            {
                dlTxt: "When needing to prepare for applications for approval to the national ethics committee on assisted reproductive technologies"
            },
            {
                dlTxt: "When thinking about being a donor or a surrogate, or about using these services"
            },
        ]
    },

    HormonalAssays: {
        dlHeading: "Infertility is due to problems related to the woman about two-thirds of the time. A doctor will likely ask a woman questions about her gynecologic history :", 
        Dldata: [
            {
                dlTxt: "Have you been pregnant before and what was the outcome of those pregnancies?"
            }, 
            {
                dlTxt: "How often have you had periods over the last year?"
            },
            {
                dlTxt: "Have you had irregular and missed periods or had spotting between periods?"
            },
            {
                dlTxt: "Have you had any changes in blood flow or the appearance of large blood clots?"
            },
            {
                dlTxt: "What methods of birth control have you used?"
            },
            {
                dlTxt: "Have you seen a doctor before for fertility problems and undergone treatment for them?"
            },
        ]
    },

    FollicularMonitoring: {
        dlHeading: "Indications And Advantages", 
        Dldata: [
            {
                dlTxt: "Egg Quality and Age: As women age, particularly into their late 30s and early 40s, the quality and quantity of their eggs decline, which can lower the chances of conception."
            }, 
            {
                dlTxt: "Importance of Follicle Monitoring: Follicle (Follicular) Tracking is crucial for women trying to conceive as it determines if they are ovulating and pinpoints the optimal time for intercourse to increase conception chances."
            }, 
            {
                dlTxt: "Role in Fertility Medication: For women on fertility treatments, follicle monitoring assesses their response by tracking the number of maturing eggs and estradiol levels, ensuring safe and effective treatment adjustments."
            }, 
            {
                dlTxt: "Estradiol Levels and Safety: Each mature egg should produce 150-200 pg/ml of estradiol. Monitoring these levels helps to avoid complications like ovarian hyperstimulation syndrome."
            }, 
            {
                dlTxt: "Guidance for Treatment: Based on the maturity of eggs, patients can be advised on timing intercourse, undergoing Intrauterine Insemination (IUI), or proceeding with egg collection for In-Vitro Fertilization (IVF)."
            }, 
            {
                dlTxt: "Identifying Abnormalities: Ultrasound monitoring also helps detect uterine, endometrial, and ovarian abnormalities such as cysts and hydrosalpinx, which may affect fertility. Dr. Neelam Bhise at ACME FERTILITY conducts these scans, requiring 3-4 clinic visits for comprehensive monitoring and management."
            }
        ]
    },

    MaleInfertility: {
        dlHeading: "Normal semen analysis", 
        Dldata: [
            {
                dlTxt: "Routine semen cultures to detect infections"
            }, 
            {
                dlTxt: "Pre- and post-processing to determine what to expect for our iui or ivf procedures"
            }, 
            {
                dlTxt: "Testing for antisperm antibodies and for iga, igm, and igg antibodies"
            }, 
            {
                dlTxt: "Long-term survival studies"
            }, 
            {
                dlTxt: "Detection of biochemical markers in the semen, e.g., fructose testing and also additional diagnostic testing for patients with severe male problems e.g. Hos test."
            }, 
            {
                dlTxt: "In cases where the semen analysis is normal, treatment will focus on the work-up of the female partner only. According to who a normal semen analysis includes:"
            }, 
            {
                dlTxt: "A sperm concentration of greater than 20 million sperm /ml"
            }, 
            {
                dlTxt: "Motility (movement) of greater than 40%, and A volume greater than 2cc."
            }, 
        ]
    },

    UrinaryIncontinenceManagement: {
       one:{ 
        dlHeading1: "Types", 
        Dldata1: [
            {
                dlTxt: "Stress incontinence- Also known as effort incontinence, is due essentially to insufficient strength of the pelvic floor muscles to prevent the passage of urine, especially during activities that increase intra-abdominal pressure, such as coughing, sneezing, or bearing down."
            }, 
            {
                dlTxt: "Urge incontinence- Involuntary loss of urine occurring for no apparent reason while suddenly feeling the need or urge to urinate."
            }, 
            {
                dlTxt: "Overflow incontinence- Sometimes people find that they cannot stop their bladders from constantly dribbling or continuing to dribble for some time after they have passed urine. It is as if their bladders were constantly overflowing, hence the general name overflow incontinence."
            }, 
            {
                dlTxt: "Mixed incontinence- Not uncommon in the elderly female population and can sometimes be complicated by urinary retention, which makes it a treatment challenge requiring staged multimodal treatment."
            }, 
            {
                dlTxt: "Structural incontinence- Not uncommon in the elderly female population and can sometimes be complicated by urinary retention, which makes it a treatment challenge requiring staged multimodal treatment."
            }, 
            {
                dlTxt: "Functional incontinence- Not uncommon in the elderly female population and can sometimes be complicated by urinary retention, which makes it a treatment challenge requiring staged multimodal treatment."
            }, 
            {
                dlTxt: "Nocturnal enuresis- Not uncommon in the elderly female population and can sometimes be complicated by urinary retention, which makes it a treatment challenge requiring staged multimodal treatment."
            }, 
            {
                dlTxt: "Transient incontinence- Temporary version of incontinence. It can be triggered by medications, adrenal insufficiency, mental impairment, restricted mobility, and stool impaction (severe constipation), which can push against the urinary tract and obstruct outflow."
            }, 
            {
                dlTxt: "Giggle incontinence- An involuntary response to laughter. It usually affects children."
            }, 
            {
                dlTxt: "Post-void dribbling- The phenomenon where urine remaining in the urethra after voiding the bladder slowly leaks out after urination"
            }, 
           
        ]},
        two:{ 
            dlHeading2: "Types", 
            Dldata2: [
                {
                    dlTxt: "Stress test - the patient relaxes, then coughs vigorously as the doctor watches for loss of urine."
                }, 
                {
                    dlTxt: "Urinalysis - urine is tested for evidence of infection, urinary stones, or other contributing causes."
                }, 
                {
                    dlTxt: "Blood tests - blood is taken, sent to a laboratory, and examined for substances related to causes of incontinence."
                }, 
                {
                    dlTxt: "Ultrasound - sound waves are used to visualize the kidneys, ureters, bladder, and urethra"
                }, 
                {
                    dlTxt: "Cystoscopy - a thin tube with a tiny camera is inserted in the urethra and used to see the inside of the urethra and bladder"
                }, 
                {
                    dlTxt: "Urodynamics - various techniques measure pressure in the bladder and the flow of urine."
                }, 
               
            ]},
            three:{ 
                dlHeading3: "Many surgical options have high rates of success", 
                Dldata3: [
                    {
                        dlTxt: "Slings -The procedure of choice for stress urinary incontinence in females is what is called a sling procedure. A sling usually consists of a synthetic mesh material which is placed under the urethra through one vaginal incision and two small abdominal incisions. The idea is to replace the deficient pelvic floor muscles and provide a backboard of support under the urethra."
                    }, 
                    {
                        dlTxt: "The tension-free transvaginal tape(TVT) - The tension-free transvaginal tape(TVT) sling procedure treats urinary stress incontinence by positioning a polypropylene mesh tape underneath the urethra. The 20-minute outpatient procedure involves two miniature incisions and has an 86-95% cure rate. Complications, such as bladder perforation, can occur in the retropubic space if the procedure is not done correctly. This minimally invasive procedure is a common treatment for stress urinary incontinence."
                    }, 
                    {
                        dlTxt: "The transobturator tape (TOT) - The transobturator tape (TOT) sling procedure aims to eliminate stress urinary incontinence by providing support under the urethra. This minimally-invasive procedure eliminates retropubic needle passage and involves inserting a mesh tape under the urethra through three small incisions in the groin area. With the cure rate of more than 90 % this procedure is rated as the safest"
                    }, 
                    {
                        dlTxt: "The mini-sling - The mini-sling procedure also known as TVT-Secure. The reported short term cure rates of the TVT-Secure ranged from 67% to 83%."
                    }, 
                    {
                        dlTxt: " Bladder repositioning - Most stress incontinence in women results from the bladder displacing down toward the vagina. Therefore, common surgery for stress incontinence involves pulling the bladder up to a more normal position. This not only holds up the bladder but also compresses the bottom if the bladder and the top of the urethra, further preventing leakage."
                    }, 
                ]},
    },

    IVF_ICSI:{
        one: {
            dlHeading: 'Indications for ART',
            Dldata: [
                {
                    dlTxt: "Both Fallopian tubes blocked as a result of any disease or previous surgical procedure"
                },
                {
                    dlTxt: "The partner with reduced sperm count (Oligozoospermia) or motility (Asthenospermia)"
                },
                {
                    dlTxt: "Antibodies to the gamete"
                },
                {
                    dlTxt: "Endometriosis i.e. the endometrium (lining of womb) which is normally inside the uterus, appears on the structures outside the uterus"
                },
                {
                    dlTxt: "Unexplained Infertility (refer to couples in whom no obvious pathology is found but who cannot conceive in spite of primary treatments like ovulation induction and IUI)."
                },
                {
                    dlTxt: "IVF also helps women who have absent ovaries or where there are no eggs in the ovaries provided any young member of the family with proven fertility is willing to donate her oocytes"
                },
                
            ]
        },
        two: {
            dlHeading2: 'Indications for ICSI',
            Dldata2: [
                {
                    dlTxt: "Sever male factor infertility where the sperm count is extremely low"
                },
                {
                    dlTxt: "Unexplained infertility"
                },
                {
                    dlTxt: "Patients having Immunological factor"
                },
                {
                    dlTxt: "Repeated failed IVF etc."
                },
                {
                    dlTxt: "Egg zona (outer layer of egg) is excessively thick and impenetrable for the sperm."
                },
                {
                    dlTxt: "To reduce transmission rates of blood borne diseases"
                },
                {
                    dlTxt: "Here, a morphologically normal sperm is selected and is injected into the center of an egg. Whole procedure is closely monitored under inverted microscope at the magnification of about 400X. It has been shown that ICSI resulted in better egg fertilization rates, better quality embryos, better rate of blastocyst formation and therefore better pregnancy rates."
                },
                
            ]
        }
    },
    AssistedHeching:{
        dlHeading: 'Indications for Assisted zona hatching',
            Dldata: [
                {
                    dlTxt: "The woman is aged 35 years or older"
                },
                {
                    dlTxt: "The woman has high FSH levels"
                },
                {
                    dlTxt: "Couples who have failed to get pregnant following previous IVF cycles"
                },
                {
                    dlTxt: "Couples where a distinct thickening of the zona is noted by the embryologist"
                },
                {
                    dlTxt: "Frozen embryo replacements"
                },
                {
                    dlTxt: "We believe that the use of assisted hatching results in higher pregnancy rates in very selected cases. For example, it has been noted that in the older woman the zona pellucida around the embryo can appear to be thickened. The making of a 'weak point' in the zona may help implantation. But in routine cases there is no convincing evidence that it helps to improve chances of pregnancy"
                },
                
            ]
    },
    PGS:{
        one:{
            dlHeading: 'Your specialist may recommend PGS if: For Male',
            Dldata: [
                {
                    dlTxt: "you are over 35 and have a higher risk of having a baby with a chromosome problem (such as Down's syndrome)"
                },
                {
                    dlTxt: "you have a family history of chromosome problems"
                },
                {
                    dlTxt: "you have a history of recurrent miscarriages"
                },
                {
                    dlTxt: "you have had several unsuccessful cycles of IVF where embryos have been transferred"
                },
                {
                    dlTxt: "your sperm are known to be at high risk of having chromosome problems. However, various studies have questioned whether or not PGS is effective at increasing the chance of having a live birth."
                },
            ]
        },
        two:{
            dlHeading2: 'The procedure for PGS is usually as follows',
            Dldata2: [
                {
                    dlTxt: "You undergo normal IVF or ICSI treatment to collect and fertilise your eggs."
                },
                {
                    dlTxt: "The embryo is grown in the laboratory for two to three days until the cells have divided and the embryo consists of about eight cells."
                },
                {
                    dlTxt: "A trained embryologist removes one or two of the cells (blastomeres) from the embryo."
                },
                {
                    dlTxt: "The chromosomes are examined to see how many there are and whether they are normal."
                },
                {
                    dlTxt: "One, two or three of the embryos without abnormal numbers of chromosomes are transferred to the womb so that they can develop. Any remaining unaffected embryos can be frozen for later use."
                },
                {
                    dlTxt: "Those embryos that had abnormal chromosomes are allowed to perish or may be used for research (with your consent). There are possible variations to this procedure and the trophectoderm biopsy technique can be used in some cases."
                },
                
            ]
        }
    },

    MACS: {
        dlHeading: 'MACS: does this procedure really help sperm quality?',
            Dldata: [
                {
                    dlTxt: "ICSI (Intracytoplasmatic sperm injection) or microinjection was revolutionary in IVF treatment. It quickly became a technique used worldwide to better the fertilization rates in IVF cycles. After the event of ICSI and finding a way to achieve pregnancy for a high number of impossible cases using conventional IVF, the male factor fell into a category where not as much research was done and the bulk of fertility problems researched were on the female factor- women were told to lose weight, eat healthy food, relax, get psychological help, try acupuncture, tai chi or reflexology while men had ICSI. Fortunately things are changing…20 years ago infertility was thought to be due mostly to the female factor, but in 2014 female infertility is now reportedly equal to male infertility and both partners are found to have fertility problems in 30% of all cases."
                },
                {
                    dlTxt: "What has happened? Are men really that bad off in 2014? Honestly, I believe that life is more stressful for all, but also we are now taking into account that ICSI cannot overcome all male problems. Numerous reports are published on how sugary drinks, cannabis, being overweight, eating red meat etc are all harmful for sperm. We are testing DNA fragmentation and the genetic side of sperm. There are dozens of anti-oxidant vitamin preparations on the market to better sperm… but is there anything else we can do?"
                },
                {
                    dlTxt: "Actually, over the past years a technique that was invented over 20 years ago as been recovered to better sperm samples the day they are to be used for artificial insemination or IVF. The method is called MACS (Magnetic Activated Cell Sorting)."
                },
                {
                    dlTxt: "The method is quite ingenious and simple. Tiny magnetic balls are dropped into a sperm sample- these magnetic balls have been coated with annexin V. Annexin V is a substance that has affinity for phosphatidylserine, a substance found in the cell membrane (and of course in the sperm cell membrane in our case) and it begins to be discernible at the cell surface when the sperm cell is about to die. So if we drop the magnetic annexin V balls into a sperm sample and pour the sperm sample through a column with magnets, we are capable of eliminating the sperm that is about to die and is useless in fertilizing an egg. This way we can magnetically “pull out” a high percentage of bad sperm in a sample. This leaves the best sperm to be used. This helps the embryologist pick the healthiest sperm when choosing sperm for microinjection and in a sperm sample to be used in Artificial Insemination, the concentration of good sperm is higher. Comparing studies over the past years it seems that MACS is a safe and efficient method to select sperm with constant results. When used along with standard sperm selection methods in Assisted Reproduction, MACS may improve pregnancy rates. This method is one that can be used only in certain cases. It must be noted that MACS is not a total revolution in Assisted Reproduction but, achieving pregnancy is a multi-factorial equation and sometimes just improving one factor can mean attaining a dream."
                },
               
            ]
    },

    GamateDonars: {
        first: {
            dlHeading: 'Who will benefit from Oocyte Donation :',
            Dldata: [
                {
                    dlTxt: "Women with elevated FSH/LH level who are in premature ovarian failure."
                },
                {
                    dlTxt: "Those with Chromosomal anomalies."
                },
                {
                    dlTxt: "Surgical menopause."
                },
                {
                    dlTxt: "Perimenopausal women with repeated IVF failures (own eggs)."
                },
                {
                    dlTxt: "Women with very low ovarian reserve."
                },
                
               
            ]
        }
    },
    PICSI: {
        dlHeading: 'PICSI technique improves pregnancy rates and reduces the number of IVF miscarriages',
            Dldata: [
                {
                    dlTxt: "In the ICSI procedure, an individual sperm is selected and injected into an oocyte. Until now, the only technique available to embryologists to select the sperm has been visual observation. Using PICSI procedure we are able to determine sperm selection in much the same way it happens in human biology. Sperms are placed in PICSI dish containing samples of hyaluronan hydrogel. Hyaluronan is a naturally occurring biopolymer found in all human cells, including the gel layer surrounding the oocyte."
                },
                {
                    dlTxt: "Mature, biochemically competent sperm bind to the hyaluronan where they can be isolated by the embryologist and used for ICSI. This procedure mimics a key step in the natural fertilization process, the binding of mature sperm to the oocyte complex. As a result, the selected sperm is essentially the same as one that would be successful in the natural reproductive process.The research proved that hyaluronan-bound PICSI-selected sperm are, in the vast majority of cases, more mature, exhibit less DNA damage, and have fewer chromosomal aneuploidyes."
                },
            ]
    },
}

export default DbListData