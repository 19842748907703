import React, { useRef, useState } from "react";
import "../../Style.scss";
import '../../responsive.scss'
import logo from "../../Assets/images/logo/logo.png";
import { Link, NavLink, useLocation } from "react-router-dom";

const Navbar2 = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () =>{
        setIsOpen(!isOpen);
    }


    const location = useLocation();
    const about = useRef();
    const service = useRef();

    const AddActive = () =>{
        if (about.current) {
            about.current.style.color = "#05BB8F";
        }
        
    }
    const ActiveService = () =>{
        if (service.current) {
            service.current.style.color = "#05BB8F";
        }
    }
    const RemoveActive = () =>{
        if (about.current) {
            about.current.style.color = "black";
        }
    }
    const RemoveService = () =>{
        if (service.current) {
            service.current.style.color = "black";
        }
    }

    const isAboutActive = ["/Introduction", "/QualityExpertise", "/PersonalizedCare", "/AdvancedTechnology", "/AffordableTreatment", "/RightEnvironment"].includes(location.pathname);
    const isServiceActive = ["/LaparoscopyHysteroscopy", "/WomenHealthCare", "/FertilityCounselling", "/HormonalAssays", "/FollicularMonitoring", "/MaleInfertility", "/ObstetricCare", "/UrinaryIncontinenceManagement"].includes(location.pathname);
  return (
    <>
    <header>
        <div className="container-fluid">
            <nav>
                <Link to="/" className="navbar-brand">
                    <img src={logo} className="main-logo" alt="logo" />
                </Link>
                <div className={isOpen ? 'nav-linkss active' : 'nav-linkss'}>
                    <ul className="navbar-nav">
                    <li className="nav-item" onClick={toggleMenu}>
                        <NavLink to="/" className="nav-link" >
                        Home
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <Link ref={about} className={`nav-link dropdown-toggle ${isAboutActive ? AddActive() : RemoveActive() }`} >About</Link>
                        <ul className="dropdown-menu">
                        <li className="nav-item" onClick={toggleMenu}>
                            <NavLink to="/Introduction" className="nav-link">
                            Introduction
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={toggleMenu}>
                            <NavLink to="/QualityExpertise" className="nav-link">
                            Quality Expertise
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={toggleMenu}>
                            <NavLink to="/PersonalizedCare" className="nav-link">
                            Personalised Care
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={toggleMenu}>
                            <NavLink to="/AdvancedTechnology" className="nav-link">
                            Advanced Technology
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={toggleMenu}>
                            <Link to="/AffordableTreatment" className="nav-link">
                            Affordable Treatments
                            </Link>
                        </li>
                        <li className="nav-item" onClick={toggleMenu}>
                            <Link to="/RightEnvironment" className="nav-link">
                            Right Environment
                            </Link>
                        </li>
                        </ul>
                    </li>
                    <li className="nav-item" onClick={toggleMenu}>
                        <NavLink to="/Programe" className="nav-link">
                        Programe
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <Link ref={service} className={`nav-link dropdown-toggle ${isServiceActive ? ActiveService() : RemoveService() }`}>
                        Services
                        </Link>
                        <ul className="dropdown-menu">
                        <li className="nav-item" onClick={toggleMenu} >
                            <NavLink
                            to="/LaparoscopyHysteroscopy"
                            className="nav-link"
                            >
                            Laparoscopy & Hysteroscopy
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={toggleMenu}>
                            <NavLink to="/WomenHealthCare" className="nav-link">
                            Women Health Care
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={toggleMenu}>
                            <NavLink to="/FertilityCounselling" className="nav-link">
                            Fertility Counselling
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={toggleMenu}>
                            <NavLink to="/HormonalAssays" className="nav-link">
                            Hormonal Assays
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={toggleMenu}>
                            <NavLink to="/FollicularMonitoring" className="nav-link">
                            Follicular Monitoring
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={toggleMenu}>
                            <NavLink to="/MaleInfertility" className="nav-link">
                            Male Infertility
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={toggleMenu}>
                            <NavLink to="/ObstetricCare" className="nav-link">
                            Obstetric Care
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={toggleMenu}>
                            <NavLink
                            to="/UrinaryIncontinenceManagement"
                            className="nav-link"
                            >
                            Urinary Incontinence Management
                            </NavLink>
                        </li>
                        </ul>
                    </li>

                    <li className="nav-item" onClick={toggleMenu}>
                        <NavLink to="/Doctor" className="nav-link">
                        Doctors
                        </NavLink>
                    </li>

                    <li className="nav-item" onClick={toggleMenu}>
                        <NavLink to="/FAQ" className="nav-link">
                        FAQ
                        </NavLink>
                    </li>
                    </ul>

                    <div className="others-options ms-5 MYms-1">
                    <Link to="/Contact" className="common-btn style1" onClick={toggleMenu}>
                        Contact
                        <span>
                        {" "}
                        <i className="bx bx-plus"></i>
                        </span>
                    </Link>
                    </div>
                </div>
                <div className="MenuIcon" onClick={toggleMenu}>
                    <i class='bx bx-menu-alt-right'></i>
                </div>
            </nav>
        </div>
    </header>
    </>
  )
}

export default Navbar2