
const AboutInfo = {
  intruduction: {
    bg: require('../../../Assets/images/about/intro.png'),
    doctor: require('../../../Assets/images/popular/Maam.png'),
    title: "Introduction",
    data:[
        {
            pointName: "Advanced Medical Center",
            pOne: "ACME FERTILITY is an advanced medical center with a team dedicated to providing the highest quality of health care. The aim of ACME FERTILITY is to achieve the highest possible standards in all aspect of medical practice - a personalized, clinical approach that aims to minimize medical and surgical intervention, yet offers you the best treatments and option available",

            pTwo: "When couples wish joy of parenthood and it is not achieved what follows is a lot of anxiety, stress. During this treatment course couple goes through a lot of emotional stress with almost same results at different IVF setups. A couple wants to know about which manner the ultimate dream can be achieved in a cost-effective way. The individualized approach can only come after a thorough evaluation and personal discussion with the doctor. In today's world of rapidly changing technology we have always updated our lab with latest equipment's and protocols. With the efforts of our highly accomplished embryologists in embryology laboratory, we aim to achieve the highest success for you. We have a special team which will ensure to give best treatment to you and your lovely baby throughout your pregnancy and thereafter also."
        },
    ]
  },

  qualityExpertise: {
    bg: require('../../../Assets/images/about/QE.png'),
    doctor: require('../../../Assets/images/popular/Maam.png'),
    title: "Quality & Expertise",
    data:[
        {
            pointName: "Quality maintenance",
            pOne: "Quality maintenance is utmost important in IVF laboratory because compromising even a single step can affect the results. Taking quality to the highest levels is one of ACME FERTILITY clinic & IVF center's core values. We strive towards clinical excellence and innovative practice. Our ongoing commitment to research enhances practice and outcomes for patients across all of our services. We here have developed the process to think about quality-improvement efforts. Clinical excellence is not a day's effort; it's been a continuous process of sincere hard work and up gradation of knowledge and skills, which has made us one of the successful IVF units",

            pTwo: "Experts are the professionals who have gained the position by actually mastery in the subject. Benefit of treatment with the help of experts rule out the rare problems which arises during the treatment. Our Reproductive Endocrinologists and staff are highly trained and experienced in the diagnosis and treatment of infertility and other reproductive disorders."
        },
        {
            pointName: "1. Quality",
            pOne: "Quality is never an afterthought at ACME FERTILITY - it is embedded in the spirit of the organization and in its processes. Quality is something we design in throughout our procedures cycle.",
            pTwo: ""
        },
        {
            pointName: "2. Team",
            pOne: "At ACME FERTILITY our team works on each aspect from medication to procedures. So forth we have achieved successful pregnancies & live birth in patients who have previously been dismayed.",
            pTwo: ""
        },
        {
            pointName: "3. Practice",
            pOne: "We share a very high success rate in fertility management, purely based on genuine and ethical practice. Our patient centric approach helped us to be popular in short time in central suburbs.",
            pTwo: ""
        },
        {
            pointName: "4. Principles",
            pOne: "A robust healthcare system relies on comprehensive evidence-based practice, genuine consultation with stakeholders, and independent advisory committees. Transparent processes and decision-making, improved work culture and organizational design, clinical and patient input, regular review of past cases, and effective implementation of policies are essential for continuous improvement and public trust.",
            pTwo: ""
        },
    ]
  },

  personalizedCare: {
    bg: require('../../../Assets/images/about/personalCare.png'),
    doctor: require('../../../Assets/images/popular/Maam.png'),
    title: "personalized Care",
    data:[
        {
            pointName: "Customize and Personalize",
            pOne: "Building in processes and mechanisms to customize and personalize the patient experience is a key strategy for overcoming the fear, anxiety and stress associated with being at the hospital. We at ACME FERTILITY take personal care of all our patients, concentrate on them individually, till we get success.",

            pTwo: " We do believe in psychological aspect of the fertility issue. We make feel them more homely and comfortable. We tell our patients what to do and what to eat, to have normal sleeping patterns disrupted by inflexible hospital routines.. We take care of this personally."
        },
        {
            pointName: "Personalization of care:",
            pOne: "We encourage patient involvement and empowerment, including patients and families and focus on how to create a more homelike environment. Such experiences are some of the ways which provide us to re frame past practices.",

            pTwo: ""
        },
        {
            pointName: "Continuity of Care:",
            pOne: "It is important for us to remember the history for many patients, so we have all the records maintained properly and filled. We do give reminders to patients for blood tests, injections and follow up so the important step of the treatment is not missed out. Health care can feel like a complex and intimidating maze that patients are forced to navigate without the benefit of a map or a guide to help them find their way, but once you are here you need not worry about it.",

            pTwo: ""
        },
        {
            pointName: "Spirituality:",
            pOne: "Spirituality is our own internal ability to find meaning and connectedness in life, and is not limited to religious traditions. Patients and families interacting with us are often in the midst of challenging times or significant life events. We recognize the wide range of patient and family needs, and implement unique programs toward supporting the mind, body and spirit. We have Yoga specialist at our place which helps you to make yourself strong.",

            pTwo: ""
        },
        {
            pointName: "Integrative medicine:",
            pOne: "Success is by a team spirit. We have a team of trained doctors at ACME FERTILITY. But to give 100% efforts we have a Dietician and a Physiotherapist attached to us. A fundamental aim of our clinic is empowering patients with the knowledge, support and resources they need to make informed decisions and to manage their health and wellness. This integrated approach has helped us in many successful patients",

            pTwo: ""
        },
    ]
  },

  advancedTechnology: {
    bg: require('../../../Assets/images/about/advanceTech.png'),
    doctor: require('../../../Assets/images/popular/Maam.png'),
    title: "Advanced Technology",
    data: [
        {
            pointName: "Vitro Fertilization",
            pOne: "In vitro fertilization, popularly referred to as IVF, has captured the attention of the public since its sensational introduction in 1978. Today assisted reproductive technology is available throughout most of the civilized world, and the practice is largely different from that used during the early days. Refinements in laboratory technology and clinical practice have allowed IVF to evolve into a medical procedure that is efficient, safe, readily accessible, and relatively affordable. More than 2 million IVF children have been born to date, and it is likely that continued enhancements will widen its appeal and applicability.",
            pTwo: "Technology and clinical excellence are most important issues in achieving higher success rate in fertility problems. Our ACME FERTILITY clinic & IVF center is always technologically updated, world is changing very fast, so as we. Because we wish to achieve success in every patient we manage",
        },
        {
            pointName: "Ultrasonography:",
            pOne: "Ultrasonography is very important for studying the progress of ovaries, uterus. We here use trans vaginalultrasound, and ultrasound guided trans vaginal follicle aspiration. This oocyte retrieval technique gives better visualization, finer control, and less patient discomfort compared with other available methods. Obviating the need for laparoscopy decreased the number of personnel, time and procedure expense, reduced the risks of surgery and general anesthesia, and provided greater patient acceptance.",
            pTwo: "IVF cases transitioned from 1-2 hours of hospital-based operating room time to 10-15 minute procedures that can be performed in an office setting."
        },
        {
            pointName: "Air purification",
            pOne: "The environment in IVF center is one of most important issue in improving success rate. Perfumes, deodorants or any smelling substance is injurious to the growth of embryos. We at ACME FERTILITY clinic & IVF center use 3 different air purification machines which have 4 -5 different types of filters built in it to maintain clean, healthy and embryo friendly environment in IVF lab. Prevention is best treatment, we strictly do not allow any outside infection to enter in IVF lab. This is done by a planned process while entering in IVF lab. Hand wash is very religiously followed at our center.",
            pTwo: ""
        },
        {
            pointName: "ICSI:",
            pOne: "As IVF became more common place in the treatment of female infertility, male infertility remained a limiting factor to overall success. Conventional IVF was much less effective when semen parameters were below the reference values for concentration (oligozoospermia), motility (asthenozoospermia), and morphology (teratozoopermia), resulting in significantly lower fertilization rates and fewer embryos available for transfer. Furthermore, azoospermic males were completely devoid of treatment options.",
            pTwo: "We use a novel procedure called Intracytoplasmic sperm injection (ICSI), where a single spermatozoon is microinjected into the oocyte after passage through the zona pellucida and the membranes of the oocyte. This procedure achieved fertilization rates of approximately 60%-70% when using ejaculated sperm."
        },
        {
            pointName: "Co2 incubators:",
            pOne: "Co2 incubators are used for culturing embryos. They mimic the female reproductive environment for the embryo to develop and multiply till they are transferred to uterus. We at ACME FERTILITY clinic & IVF center use one the latest and advanced incubator with strict temperature and Co2 concentration control. Its calibration is done on regular basis, so as to give us better results.",
            pTwo: ""
        },
        
    ]
  },

  AffordableTreat: {
    bg: require('../../../Assets/images/about/affordable.png'),
    doctor: require('../../../Assets/images/popular/Maam.png'),
    title: "Affordable Treatment",
    data: [
        {
            pointName: "",
            pOne: "In vitro fertilization, popularly referred to as IVF, has captured the attention of the public since its sensational introduction in 1978. Today assisted reproductive technology is available throughout most of the civilized world, and the practice is largely different from that used during the early days. Refinements in laboratory technology and clinical practice have allowed IVF to evolve into a medical procedure that is efficient, safe, readily accessible, and relatively affordable. More than 2 million IVF children have been born to date, and it is likely that continued enhancements will widen its appeal and applicability.",
            pTwo: "Technology and clinical excellence are most important issues in achieving higher success rate in fertility problems. Our ACME fertility is always technologically updated, world is changing very fast, so as we. Because we wish to achieve success in every patient we manage."
        },
        {
            pointName: "Continuity of Care:",
            pOne: "It is important for us to remember the history for many patients, so we have all the records maintained properly and filled. We do give reminders to patients for blood tests, injections and follow up so the important step of the treatment is not missed out. Health care can feel like a complex and intimidating maze that patients are forced to navigate without the benefit of a map or a guide to help them find their way, but once you are here you need not worry about it.",
            pTwo: ""
        },
        {
            pointName: "Spirituality:",
            pOne: "Spirituality is our own internal ability to find meaning and connectedness in life, and is not limited to religious traditions. Patients and families interacting with us are often in the midst of challenging times or significant life events. We recognize the wide range of patient and family needs, and implement unique programs toward supporting the mind, body and spirit. We have Yoga specialist at our place which helps you to make yourself strong.",
            pTwo: ""
        },
        {
            pointName: "Integrative medicine:",
            pOne: "Success is by a team spirit. We have a team of trained doctors at ACME fertility. But to give 100% efforts we have a Dietician and a Physiotherapist attached to us. A fundamental aim of our clinic is empowering patients with the knowledge, support and resources they need to make informed decisions and to manage their health and wellness. This integrated approach has helped us in many successful patients",
            pTwo: ""
        },
    ]
  },

  RightEnvironment:{
    bg: require('../../../Assets/images/about/rightEnv.png'),
    doctor: require('../../../Assets/images/popular/Maam.png'),
    title: "Right Environment",
    data:[
        {
            pointName: "Right Environment",
            pOne: "Today we are living in a very different kind of environment. Life is very fast and there is so much of stress on us. Fertility issue adds this stress to a very large extent. Our experts at ACME FERTILITY understand this. This stress and anxiety not only give you psychological discomfort but also decreases your chances of getting success in fertility treatment. ",

            pTwo: "So we have created a unique environment at our center so you feel homely and stress-free here. We have developed a personalized approach to every patient so that you won't feel lonely and you can share all your problems to us."
        },
        {
            pointName: "Homeliness",
            pOne: "Home is likely the most familiar setting for someone who needs care. In order to make comfort care available we have homely and friendly environment here at our center. We have created a private, comforting and compassionate clinic separated from ultrasonography room, examination room, IVF lab where patients feel at ease and well-cared-for.",

            pTwo: ""
        },
        {
            pointName: "Privacy rights",
            pOne: "We believe that respecting privacy is a form of recognition that give humans their moral uniqueness. Having privacy rights in place, individuals can rest assured that their information will not be unreasonably disseminated to parties who have no right to view such information. Patients here will be much more comfortable relaying their medical issues. We have privet places for every procedure",

            pTwo: "Privet & separate consultingrooms, privet & separate ultrasonography room, A privet & separate Semen collection room, A privet & separate IVF lab area."
        },
        {
            pointName: "Our commitment",
            pOne: "We believe that every human life is sacred and valuable. Each person possesses a basic dignity that deserves our deepest respect and reverence. We believe we must provide comfort, care and support to the suffering. It is because of our commitment to you and to these values that we offer you this guide. We hope it will help you make important decisions about your parenthood. We encourage you and your loved ones to discuss these matters with us.",

            pTwo: ""
        },
       
    ]
  }
}

export default AboutInfo