import React from 'react'
import { AboutInnerBanner } from '../../Components/Common/InnerBanner/AboutInnerBanner'
import AboutContent from '../../Components/Common/AboutContent/AboutContent'
import AboutInnBanContent from '../../Components/Common/InnerBanner/AboutInnBanContent'
import AboutInfo from '../../Components/Common/AboutContent/AboutInfo'
import Transition from '../../Components/Transition'

const PersonalizedCare = () => {
  const {image, heading, pageName} = AboutInnBanContent.personalizedCare;
  const {bg, doctor, title, data} = AboutInfo.personalizedCare;
  return (
    <>
    <AboutInnerBanner image={image} heading={heading} pageName={pageName} />
    <AboutContent bg={bg} doctor={doctor} title={title} data={data}/>
    </>
  )
}

export default Transition(PersonalizedCare) 