import React from 'react'

const TopContext = ({greenHeading, head, video, pOne, pTwo}) => {
  return (
    <>
    <div className="blog-details-widget">
          
          <div className="blog-card test-details-card">
            <div className="all-details">
                <span className='greenHeading text-center'>{greenHeading}</span>
              <h3 className='text-center'>{head}</h3>
            <video className="blog-video rounded" muted autoPlay loop >
                <source src={video} type="video/mp4"/>
            </video>
              <p className="frist-p">
                    {pOne}
              </p>
              <p>
                    {pTwo}
              </p>
            </div>
          </div>
        </div>
    </>
  )
}

export default TopContext