import React from 'react'
import TopContext from './Common/TopContext';
import TopContextData from './Common/TopContextData';
import DbList from '../Common/Services/DbList';
import DbListData from '../Common/Services/DbListData';
import AllDetailsData from '../Common/Services/AllDetailsData';
import AllDetails from '../Common/Services/AllDetails';
import SDevideData from './Common/SDevideData';
import SDevide from './Common/SDevide';


const IVF_ICSI = () => {
    const {greenHeading, head, video, pOne, pTwo} = TopContextData.IVF;
    const {greenHeading1, head1, video1, pOne1, pTwo1} = TopContextData.ICSI;
    const {dlHeading, Dldata} =  DbListData.IVF_ICSI.one;
    const {AllDData} = AllDetailsData.IVF_ICSI.first;
    const {AllDData1} = AllDetailsData.IVF_ICSI.second;
    const {sdHeading, sdImg, SDdata} = SDevideData.IVF_ICSI.Wife;
    const {sdHeading2, sdImg2, SDdata2} = SDevideData.IVF_ICSI.Husband;

    const {AllDData2} = AllDetailsData.IVF_ICSI.third;
    const {dlHeading2, Dldata2} =  DbListData.IVF_ICSI.two;


  return (
    <>
    <TopContext greenHeading={greenHeading} head={head} video={video} pOne={pOne} pTwo={pTwo} />
    <AllDetails AllDData={AllDData} />
    <DbList dlHeading={dlHeading} Dldata={Dldata} />
    <AllDetails AllDData={AllDData1} />
    <SDevide sdHeading={sdHeading} sdImg={sdImg} SDdata={SDdata}/>
    <SDevide sdHeading={sdHeading2} sdImg={sdImg2} SDdata={SDdata2}/>

    <div class="blog-card test-details-card">
      <h4>Procedure Guide</h4>
    </div>
    <AllDetails AllDData={AllDData2} />



    <TopContext greenHeading={greenHeading1} head={head1} video={video1} pOne={pOne1} pTwo={pTwo1} />
    <DbList dlHeading={dlHeading2} Dldata={Dldata2} />

    </>
  )
}

export default IVF_ICSI