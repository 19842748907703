import React from "react";

const PreLoader = () => {
  return (
    <div className="content1">
      <div className="loading">
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export default PreLoader;
