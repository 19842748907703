import React from 'react'
import { AboutInnerBanner } from '../Components/Common/InnerBanner/AboutInnerBanner'
import AboutInnBanContent from '../Components/Common/InnerBanner/AboutInnBanContent'
import DoctorProfile from '../Components/DoctorProfile/DoctorProfile';
import Transition from '../Components/Transition';

const Doctor = () => {
    const {image, heading, pageName} = AboutInnBanContent.Doctor;
  return (
    <>
    <AboutInnerBanner image={image} heading={heading} pageName={pageName} />
    <DoctorProfile />
    </>
  )
}

export default Transition(Doctor) 