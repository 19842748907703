import React from 'react'
import TopContext from './Common/TopContext'
import TopContextData from './Common/TopContextData'
import DbList from '../Common/Services/DbList';
import DbListData from '../Common/Services/DbListData';

const MACS = () => {
    const {greenHeading, head, video, pOne} = TopContextData.MACS;
    const {dlHeading, Dldata} = DbListData.MACS;
  return (
    <>
    <TopContext greenHeading={greenHeading} head={head} video={video} pOne={pOne} />
    <DbList dlHeading={dlHeading} Dldata={Dldata}/>
    </>
  )
}

export default MACS