
const TopContentTxt = {
    LaparoscopyHysteroscopy:{
        banner: require('../../../Assets/images/services/laproscopy.png') ,
        head: 'Laparoscopy And Hysteroscopy',
        pOne: 'Laparoscopic surgery, also called minimally invasive surgery (MIS), bandaid surgery, or keyhole surgery, is a modern surgical technique in which operations in the abdomen are performed through small incisions (usually 0.5-1.5 cm) as opposed to the larger incisions needed in laparotomy. Keyhole surgery makes use of images displayed on TV monitors to magnify the surgical elements.',
        pTwo: 'Laparoscopic surgery includes operations within the abdominal or pelvic cavities, There are a number of advantages to the patient with laparoscopic surgery versus an open procedure. These include reduced pain due to smaller incisions and hemorrhaging, and shorter recovery time.',
        highligth: 'The key element in laparoscopic surgery is the use of a laparoscope. a digital laparoscope where the charge-coupled device is placed at the end of the laparoscope, Also attached is a fiber optic cable system connected to a "cold" light source halogen or xenonto illuminate the operative field, inserted through a 5 mm or 10 mm cannula or trocar to view the operative field. ',
    },

    WomenHealthCare:{
        banner: require('../../../Assets/images/services/womenHealthCare.png') ,
        head: 'Women Health Care',
        pOne: 'Healthy living is living well despite your inescapable illnesses and diseases. Whereas total wellness means the balance and integration of the physical, intellectual, emotional, spiritual, occupational, environmental, and social aspects of the human condition.',
        pTwo: '',
        highligth: 'One needs to know about the leading causes of morbidity and death and if one is at risk. By taking appropriate steps you can make a difference in your health.',
    },

    FertilityCounselling:{
        banner: require('../../../Assets/images/services/fertilityCouns.png') ,
        head: 'Fertility Counselling',
        pOne: 'Infertility is fundamentally the inability to conceive a baby. Infertility also refers to the state of a woman who is unable to carry a pregnancy to full term. There are many biological causes of infertility, including some that medical intervention can treat.',
        pTwo: 'Infertility has increased and the incidence is around 15 % in married couples, and problems increases with increase in age. About 40 percent of the issues involved with infertility are due to the man, another 40 percent due to the woman and 20 percent result from complications with both partners.',
        highligth: 'Acme Fertility Hospital has the best fertility specialists in Mumbai whose counselling can help a couple or an individual feel less alone and can assist people with finding ways to cope with the emotional hurt of infertility. People may decide to talk with a counsellor at different points of their experience.',
    },

    HormonalAssays:{
        banner: require('../../../Assets/images/services/harmonals.png') ,
        head: 'Hormonal Assays',
        pOne: '',
        pTwo: '',
        highligth: 'We recommend visiting an infertility specialist for an infertility evaluation after six to 12 months of unprotected and regular sex without pregnancy. Infertility testing is best done by an infertility specialist.',
    },

    FollicularMonitoring:{
        banner: require('../../../Assets/images/services/FollicularMonitoring.png') ,
        head: 'Follicular Monitoring',
        pOne: '',
        pTwo: '',
        highligth: 'Follicular Monitoring is the process of serial ultrasonic monitoring of the ovarian follicles used to identify maturation status of eggs. It is useful for assessing the size of the follicle that supports the growing egg and for determining the thickness of the uterine lining.',
    },

    
    MaleInfertility:{
        banner: require('../../../Assets/images/services/maleInfertlity.png') ,
        head: 'Male Infertility',
        pOne: 'The male partner provides a semen sample that is analyzed with a battery of advanced andrology tests in our fully-equipped laboratory. In addition to the standard semen analysis using World Health Organization (WHO) criteria, we also analyze sperm to assess the number of motile sperm that can be extracted from the ejaculate.',
        pTwo: 'We encourage male partners to have their semen analyzed at our Laboratory so their samples can be tested against rigorous standards. In addition to the routine analysis of our morphology, motility, and concentration, some of the additional testing we perform on the semen includes:',
        highligth: 'Sperm contribute to 50 % of the embryos. This aspect of conception also carries immense importance. Usually, only test done to assess male factor is semen analysis. A proper semen analysis should give idea about different problems.',
    },
    
    ObstetricCare:{
        banner: require('../../../Assets/images/services/ObstetricCare.png') ,
        head: 'Obstetric care',
        pOne: 'Prenatal care (also known as antenatal care) refers to the regular medical and nursing care recommended for women during pregnancy. Prenatal care is a type of preventative care with the goal of providing regular check-ups that allow doctors or midwives to treat and prevent potential health problems throughout the course of the pregnancy while promoting healthy lifestyles that benefit both mother and child.',
        pTwo: 'During check-ups, women will receive medical information over maternal physiological changes in pregnancy, biological changes, and prenatal nutrition including prenatal vitamins. Recommendations on management and healthy lifestyle changes are also made during regular check-ups. The availability of routine prenatal care has played a part in reducing maternal death rates and miscarriages as well as birth defects, low birth weight, and other preventable health problems.',
        highligth: 'Acme Fertility is the best obstetrics and gynecology in Mumbai that have wholesome maternity care unit at same palce as IVF lab, so as to provide an excellent antenatal care to you. For us every pregnancy is a precious pregnancy, we do care you and your little one till the birth and even there after.',
    },
    
    UrinaryIncontinenceManagement:{
        banner: require('../../../Assets/images/services/urinary.png') ,
        head: 'Urinary Incontinence Management',
        pOne: '',
        pTwo: '',
        highligth: 'Urinary incontinence (UI), involuntary urination, is any involuntary leakage of urine. It can be a common and distressing problem, which may have a profound impact on quality of life.',
    },

    IVF_ICSI:{
        banner: require('../../../Assets/images/video/video-image.jpg') ,
        head: 'IVF And ICSI Best IVF Centre in Mumbai',
        pOne: 'At ACME FERTILITY - we have a team of best IVF specialists in Mumbai, dedicated to providing the highest quality of health care. The aim of ACME FERTILITY is to achieve the highest possible standards in all aspect of medical practice - a personalized, clinical approach that aims to minimize medical and surgical intervention, yet offers you the best IVF treatment in Mumbai and option available. We encourage you to learn as much as you can about the IVF program at best IVF centre in Mumbai.',
        pTwo: '',
        highligth: 'This section of the Web site offers an overview of medications, procedures, success rates and financial issues related to IVF. You will also have the opportunity to tour our state-of-the-art medical facilities, post your queries you may have related to infertility. Get in touch with us to know the details about IVF cost in Mumbai.',
    },
}

export default TopContentTxt