import React from 'react'
import TopContext from './Common/TopContext'
import TopContextData from './Common/TopContextData'
import AllDetailsData from '../Common/Services/AllDetailsData';
import AllDetails from '../Common/Services/AllDetails';

const EmbryoTransfer = () => {
    const {greenHeading, head, video, pOne} = TopContextData.EmbryoTransfer;
    const {AllDData} = AllDetailsData.EmbryoTransfer;

  return (
    <>
    <TopContext greenHeading={greenHeading} head={head} video={video} pOne={pOne} />
    <AllDetails AllDData={AllDData} />
    </>
  )
}

export default EmbryoTransfer;