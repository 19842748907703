import React from 'react'

const YouTube = () => {
  return (
        <div className="row ">
            <div className="col-lg-12 pt-3 d-flex justify-content-center">
            {/* <iframe src='https://widgets.sociablekit.com/youtube-channel-videos/iframe/25440644' title='youtube' frameborder='0' width='100%' height='600'></iframe> */}
              <iframe width="560" height="315" className='rounded mb-4' src="https://www.youtube.com/embed/XoNgXvRwL1w?si=kOz_BbPOGe5dhyF9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div className="col-lg-12 d-flex justify-content-center">
              <iframe width="560" height="315" className='rounded mb-4' src="https://www.youtube.com/embed/YM5UsIMUDeE?si=YT_sE9R3VgU6v7A8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div className="col-lg-12 d-flex justify-content-center">
              <iframe width="560" height="315" className='rounded mb-4' src="https://www.youtube.com/embed/Z3OXo7jLOx0?si=EH2UrvHtfAaDCqM_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div className="col-lg-12 d-flex justify-content-center">
              <iframe width="560" height="315" className='rounded mb-4' src="https://www.youtube.com/embed/zNJt_S3pgRQ?si=gzc8WAA_qvxVDq7W" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div className="col-lg-12 d-flex justify-content-center">
              <iframe width="560" height="315" className='rounded mb-4' src="https://www.youtube.com/embed/Fd3-ZCLWxpc?si=dih-OZeQ6aTDIB1x" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div className="col-lg-12 d-flex justify-content-center">
              <iframe width="560" height="315" className='rounded mb-4' src="https://www.youtube.com/embed/bWwJ4OuucKQ?si=-UIb2oa6NOn1VBtx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            {/* <div className="col-lg-12 d-flex justify-content-center">
              
            </div>
             */}
        </div>
  )
}

export default YouTube
