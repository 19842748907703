import React from 'react'
import tempor from '../../../Assets/images/popular/services/serviceMenu/tempor.png'

const TopContent = ({banner, head, pOne, pTwo, highligth}) => {
  return (
    <>
    <div className="blog-details-widget">
          <img
            src={banner}
            className="blog-details-image rounded"
            alt="bg"
          />
          <div className="blog-card test-details-card">
            <div className="all-details">
              <h3>{head}</h3>
              <p className="frist-p">
                    {pOne}
              </p>
              <p>
                    {pTwo}
              </p>
            </div>
            <div className="details-tempor">
              <h6>
                    {highligth}
              </h6>
              <img
                src={tempor}
                className="tempor"
                alt="bg"
              />
            </div>
          </div>
        </div>
    </>
  )
}

export default TopContent