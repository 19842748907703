import React from 'react'
import AboutInnBanContent from '../Components/Common/InnerBanner/AboutInnBanContent'
import { AboutInnerBanner } from '../Components/Common/InnerBanner/AboutInnerBanner';
import ProgrameTabPage from '../Components/Programe/ProgrameTabPage';
import Transition from '../Components/Transition';

const Programe = () => {
    const {image, heading, pageName} = AboutInnBanContent.Programe;

  return (
    <>
      <AboutInnerBanner image={image} heading={heading} pageName={pageName} />  
      <ProgrameTabPage />
    </>
  )
}

export default Transition(Programe) 