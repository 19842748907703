import React, { useEffect, useState } from 'react'

const BackToTopButton = () => {
    const [BacktotopButton, setBacktoTopButton] = useState(false);

    useEffect(()=>{
        window.addEventListener("scroll", () =>{
            if(window.scrollY > 400){
                setBacktoTopButton(true);
            }else{
                setBacktoTopButton(false)
            }
        })
    },[])

    const scrollUp = () =>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    const btn = {
        position : "fixed",
        bottom: " 50px",
        right: "50px",
        background: "#05BB8F",
        color: "white",
        zIndex: "99",
        height: "50px",
        width: "50px",
        borderRadius: "100%",
        fontSize: "30px"
    }
  return (
    <>
        {
            BacktotopButton && (
                <button style={btn} className='d-flex justify-content-center align-items-center' onClick={scrollUp}>
                    <i class='bx bx-up-arrow-alt'></i>
                </button>
            )
        }
    </>
  )
}

export default BackToTopButton
