import React from 'react'
import TopContext from './Common/TopContext'
import TopContextData from './Common/TopContextData'
import AllDetailsData from '../Common/Services/AllDetailsData';
import AllDetails from '../Common/Services/AllDetails';
const EmbryoCrypto = () => {
    const {greenHeading, head, video, pOne} = TopContextData.EmbryoCrypto;
    const {AllDData} = AllDetailsData.EmbryoCrypto;

  return (
    <>
    <TopContext greenHeading={greenHeading} head={head} video={video} pOne={pOne} />
    <AllDetails AllDData={AllDData} />
    
    </>
  )
}

export default EmbryoCrypto