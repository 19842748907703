import React from 'react'
import AboutContent from '../../Components/Common/AboutContent/AboutContent'
import { AboutInnerBanner } from '../../Components/Common/InnerBanner/AboutInnerBanner'
import AboutInfo from '../../Components/Common/AboutContent/AboutInfo'
import AboutInnBanContent from '../../Components/Common/InnerBanner/AboutInnBanContent'
import Transition from '../../Components/Transition'

const QualityExpertise = () => {
  const {bg, doctor, title, data} = AboutInfo.qualityExpertise;
  const {image, heading, pageName} = AboutInnBanContent.qualityExpertise
  return (
    <>
      <AboutInnerBanner image={image} heading={heading} pageName={pageName}/>
      <AboutContent bg={bg} doctor={doctor} title={title} data={data} />
    </>
  )
}

export default Transition(QualityExpertise) 