import React from "react";
import img1 from "../../Assets/images/FAQ/process.png";
// import img2 from "../../Assets/images/FAQ/embryo-unscreen.gif";
// import img3 from "../../Assets/images/FAQ/13086029-unscreen.gif";
// import img4 from "../../Assets/images/FAQ/11290481-unscreen.gif";

const FAQContent = () => {
  return (
    <>
      <div className="process-area ptb-100 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 aos-init">
              <div className="proces-image">
                <img src={img1} alt="main"/>
                {/* <div className="p-shape">
                  <img src={img2} className="process-1" alt="1"/>
                  <img src={img3} className="process-2" alt="2"/>
                  <img src={img4} className="process-3" alt="3"/>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 aos-init aos-animate">
              <div className="process-content">
                <span>FAQ</span>
                <h3>Acme Fertility Hospital FAQ</h3>
                <p>
                Your Comprehensive Guide to Fertility Treatments and Services
                Welcome to Acme Fertility Hospital's FAQ section, where we provide detailed answers to all your questions about our fertility treatments and services. Our goal is to offer clear and helpful information to support you on your journey to parenthood.
                </p>
                <p>
                Explore our FAQ section for expert answers on fertility, infertility causes, and treatment options. Get informed about our services and empower yourself to make confident decisions about your reproductive health. Have specific questions? Contact our team for personalized support on your journey to parenthood.
                </p>
                <p>
                At Acme Fertility Hospital, we offer a wide range of fertility treatments tailored to meet the unique needs of each patient. Our services include In Vitro Fertilization (IVF), Intrauterine Insemination (IUI), egg freezing, sperm banking, and more. Each treatment plan is designed with the latest medical advancements to ensure the highest chances of success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="process-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div class="accordion accordion-flush" id="accordionFlushExample">
                {data3.map((d) => (
                  <div class="accordion-item">
                    <h2 class="accordion-header" id={d.ID}>
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={d.HashID1}
                        aria-expanded="false"
                        aria-controls={d.ID1}
                      >
                        <span>{d.ANumber}</span>
                        {d.title}
                      </button>
                    </h2>
                    <div
                      id={d.ID1}
                      class="accordion-collapse collapse"
                      aria-labelledby={d.ID}
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">{d.content}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQContent;

const data3 = [
  {
    ID: "one",
    HashID1: "#one1",
    ID1: "one1",
    ANumber: "01",
    title: " What is infertility?",
    content:
      "Infertility, whether male or female, can be defined as 'the inability of a couple to achieve conception or to bring a pregnancy to term after a year or more of regular, unprotected intercourse'.",
  },
  {
    ID: "two",
    HashID1: "#two2",
    ID1: "two2",
    ANumber: "02",
    title: "What is the incidence of infertility worldwide?",
    content:
      "The World Health Organization (WHO) estimates that approximately 8-10% of couples experience some form of infertility problems. On a worldwide scale, this means that 50-80 million people suffer from infertility. However, the incidence of infertility may vary from region to region.",
  },
  {
    ID: "three",
    HashID1: "#three3",
    ID1: "three3",
    ANumber: "03",
    title: "Is infertility exclusively a female problem?",
    content:
      "No. The incidence of infertility in men and women is almost identical. Infertility is exclusively a female problem in 30-40% of the cases and exclusively a male problem in 30-40% of the cases. Problems common to both partners are diagnosed in 10-15% of infertile couples. After thorough medical investigations, the causes of the fertility problem remain unexplained in only a minority of infertile couples (5-10%). This is called unexplained infertility.",
  },
  {
    ID: "four",
    HashID1: "#four4",
    ID1: "four4",
    ANumber: "04",
    title: "How is the Semen obtained in Semen Banking?",
    content:
      "A donor male donates the semen after he is screened and tested as safe for various infections such as HIV, Hepatitis and other Venereal Diseases. At our center we test the donor for infections every 3 months.",
  },
  {
    ID: "five",
    HashID1: "#five5",
    ID1: "five5",
    ANumber: "05",
    title: "What are the most common causes of infertility?",
    content:
      "The most common causes of female infertility are ovulatory disorders and anatomical abnormalities such as damaged fallopian tubes. Less frequent causes include, for example, endometriosis and hyperprolactinemia, thyroid gland related problems. In developing countries like India, infections of the womb such as gonorrhoea, chlamydia and tuberculosis significantly, contribute to infertility.In developing countries like India, infections of the womb such as gonorrhoea, chlamydia and tuberculosis significantly, contribute to infertility.",
  },
  {
    ID: "six",
    HashID1: "#six6",
    ID1: "six6",
    ANumber: "06",
    title: "Causes of male infertility ?",
    content:
      "Thesecan be divided into three main categories: Sperm production disorders affecting the quality and/or the quantity of sperm; Anatomical obstructions; Other factors such immunological disorders.",
  },
  {
    ID: "seven",
    HashID1: "#seven7",
    ID1: "seven7",
    ANumber: "07",
    title: "At what time of the month is a woman most fertile?",
    content:
      "The day you start your menstrual period is “Day 1.” Around “Day 14″ you may ovulate and release an egg. The egg is viable for 24 hours; this is your most fertile time. However, not all women ovulate on “Day 14.” Some ovulate earlier and some later. Some women do not ovulate at all. Your ovulation pattern can vary month to month. Even if you are getting your period, that does not mean you are ovulating",
  },
  {
    ID: "eight",
    HashID1: "#eight48",
    ID1: "eight8",
    ANumber: "08",
    title: "What is artificial insemination?",
    content:
      "Artificial insemination is now more commonly referred to as IUI (intrauterine insemination). It is a procedure used for couples with unexplained infertility, minimal male factor infertility, and women with cervical mucus problems. The procedure uses the husband's or donor's sperm, washing and treating the sperm, and then injecting it into the woman during the time of ovulation.",
  },
  {
    ID: "nine",
    HashID1: "#nine9",
    ID1: "nine9",
    ANumber: "09",
    title:
      "What is artificial insemination with Donor sample (AID) or Donor Insemination (DI)?",
    content:
      "In as many as 30 % of infertile couples the male is responsible for infertility. A significant percentage of these males do not father children despite various treatments including ICSI. The solution is inseminating their wives with semen of another male (donor) at the time of her ovulation. This procedure is called AID or DI.",
  },
  {
    ID: "ten",
    HashID1: "#ten10",
    ID1: "ten10",
    ANumber: "10",
    title: "Who can benefit from AID?",
    content:
      "AID (Artificial Insemination Donor ) is generally advocated for males with low semen counts (Oligospermia or zero counts) (Azoospermia), where treatment with drugs, surgery and ART treatments like AIH, IUI and ICSI have proven unsuccessful. It is an acceptable alternative to adoption.",
  },
];
