const DbDevideData = {
    WomenHealthCare:{
        dbHeading: "Today's approach towards woman health",
        dbImg: require('../../../Assets/images/popular/services/serviceMenu/blog-details-2.jpg'),
        DBdata:[
            {
                dbTxt:'Symptoms in women are well known and treated effectively.'
            },
            {
                dbTxt:'More reliable testing (gender specific) methods are available.'
            },
            {
                dbTxt:'Screenings and prevention are extremely important.'
            },
            {
                dbTxt:'Easier and more supportive environment is available.'
            },
            {
                dbTxt:'Screenings for cervical cancer has resulted in a 40% decline in the incidence and death from the disease.'
            },
            {
                dbTxt:'Breast cancer has declined tremendously.'
            },
        ]
    },

    HormonalAssays: {
        dbHeading: "The first step is a detailed interview. A thorough infertility interview should involve both partners, and ask about:",
        dbImg: require('../../../Assets/images/services/innerImage/harmonals.png'),
        DBdata:[
            {
                dbTxt:'Your medical histories, including any chronic illnesses or surgeries'
            },
            {
                dbTxt:'Your use of prescription medication'
            },
            {
                dbTxt:'Your use of caffeine, alcohol, cigarettes, and drugs'
            },
            {
                dbTxt:'Your exposure to chemicals, toxins, or radiation in the home or at work'
            },
            {
                dbTxt:'How often you have sex'
            },
            {
                dbTxt:'Your history of birth control use'
            },
            {
                dbTxt:'Any history of sexually transmitted diseases'
            },
            {
                dbTxt:'Any problems having sex'
            },
        ]
    },

    FollicularMonitoring: {
        dbHeading: "Procedure",
        dbImg: require('../../../Assets/images/services/innerImage/FollicularMonitoring.png'),
        DBdata:[
            {
                dbTxt:'The egg develops within a fluid-filled follicle in the ovary, appearing as a circular bubble on a screen when 7-8 mm in size.'
            },
            {
                dbTxt:'The follicle grows at a rate of 1-2 mm per day and is ready for ovulation when it measures 17-25 mm in diameter.'
            },
            {
                dbTxt:'Follicle Tracking involves measuring the number and size of developing follicles and noting ovulation if it occurs.'
            },
            {
                dbTxt:'The development and thickness of the uterine lining are recorded to ensure it is receptive for embryo implantation.'
            },
            {
                dbTxt:'The process ensures that both the follicles and the uterine lining are developing properly to support potential pregnancy.'
            },
        ]
    },

    MaleInfertility: {
        dbHeading: "Male factor problems may be related to",
        dbImg: require('../../../Assets/images/services/innerImage/maleInfertility.png'),
        DBdata:[
            {
                dbTxt:'Inadequate or abnormal sperm production and delivery'
            },
            {
                dbTxt:'Anatomical problems'
            },
            {
                dbTxt:'Previous testicular injuries, or'
            },
            {
                dbTxt:'Hormonal imbalances.'
            },
            
        ]
    },

    ObstetricCare: {
        first: {
            dbHeading1: "Prenatal care generally consists of",
            dbImg1: require('../../../Assets/images/services/innerImage/ObstetricCare.png'),
            DBdata1:[
                {
                    dbTxt:'Monthly visits to the doctors during the first two trimesters (from week 1-28)'
                },
                {
                    dbTxt:'Fortnightly visits to doctor from 28th week to 36th week of pregnancy'
                },
                {
                    dbTxt:'Weekly visits to doctor after 36th week till delivery(delivery at week 38-40)'
                },
                {
                    dbTxt:'Assessment of parental needs and family dynamic.'
                },
                {
                    dbTxt:'Detection of risk factors and its management.'
                },
                {
                    dbTxt:'Care for pregnancies with previous history of complications.'
                },
                
            ]
        },

        Two: {
            dbHeading2: "Generally an ultrasound is ordered along a schedule similar to the following",
            dbImg2: require('../../../Assets/images/services/innerImage/ObstetricCare1.png'),
            DBdata2:[
                {
                    dbTxt:"7 weeks — confirm pregnancy, ensure that it's neither molar or ectopic, determine due date"
                },
                {
                    dbTxt:'13-14 weeks — evaluate the possibility of Down Syndrome'
                },
                {
                    dbTxt:'18-20 weeks — see the reasons above'
                },
                {
                    dbTxt:'34 weeks — evaluate size, verify placental position'
                },
            ]
        }
    },
    
    
}

export default DbDevideData