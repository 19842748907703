import React from 'react'
import { AboutInnerBanner } from '../../Components/Common/InnerBanner/AboutInnerBanner';
import AboutInnBanContent from '../../Components/Common/InnerBanner/AboutInnBanContent';
import RightHandSide from '../../Components/Common/Services/RightHandSide';
import TopContent from '../../Components/Common/Services/TopContent';
import TopContentTxt from '../../Components/Common/Services/TopContentTxt';
import AllDetails from '../../Components/Common/Services/AllDetails';
import AllDetailsData from '../../Components/Common/Services/AllDetailsData';
import DbListData from '../../Components/Common/Services/DbListData';
import DbList from '../../Components/Common/Services/DbList';
import Transition from '../../Components/Transition';


const LaparoscopyHysteroscopy = () => {
  const {image, heading, pageName} = AboutInnBanContent.LaparoscopyHysteroscopy;
  const {banner, head, pOne, pTwo, highligth} = TopContentTxt.LaparoscopyHysteroscopy;
  const {AllDData} = AllDetailsData.LaparoscopyHysteroscopy;
  const {dlHeading, Dldata} = DbListData.LaparoscopyHysteroscopy.Indications;
  const {pHeading, pData} = DbListData.LaparoscopyHysteroscopy.Procedure;


  return (
    <>
    <AboutInnerBanner image={image} heading={heading} pageName={pageName} />

    <div className="blog-details-area pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <TopContent banner={banner} head={head} pOne={pOne} pTwo={pTwo} highligth={highligth} />

            <AllDetails AllDData={AllDData} />

            <DbList dlHeading={dlHeading} Dldata={Dldata} />

            <DbList dlHeading={pHeading} Dldata={pData} />

          </div>
          <div className="col-lg-4">
            <RightHandSide />
        </div>
        </div>
      </div>
    </div>


    </>
  )
}

export default Transition(LaparoscopyHysteroscopy) 
