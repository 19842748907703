import React from 'react';
import { motion } from 'framer-motion';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import icon from "../../Assets/images/icons/copy-solid-24.png"

import "../../Style.scss";
import { Link } from 'react-router-dom';



export const OurServices = (props) => {
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 1060,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
      };

  return (
        <div className="popular-area pb-70 pt-100">
        <div className="container">
            <div className="section-title">
                <motion.span
                    initial = {{y:50, opacity: 0}}
                    whileInView={{y:0, opacity: 1}}
                    transition={{ 
                        y:{type: "spring", stiffness: 60}, 
                        opacity: {duration: 0.2},
                        ease: "easeIn",
                        duration: 1
                    }}
                >Best fertility center</motion.span>
                <motion.h2
                    initial = {{y:50, opacity: 0}}
                    whileInView={{y:0, opacity: 1}}
                    transition={{ 
                        delay: 0.9,
                        y:{type: "spring", stiffness: 60}, 
                        opacity: {duration: 0.5},
                        ease: "easeIn",
                        duration: 5
                    }}
                >Our Services</motion.h2>
            </div>
            <Slider {...settings} className="popular-slider owl-carousel">
                {data.map((d, index)=>(
                    <Link key={index}  to={d.LinkTo}>
                    <motion.div
                        initial={{ y: 100, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{ 
                            delay: 2,
                            y: { type: "spring", stiffness: 60 }, 
                            opacity: { duration: 0.9 },
                            ease: "easeIn",
                            duration: 2
                        }}
                    className="popular-card" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200" data-aos-once="true">
                    <img src={d.img} alt="1" className='serImg' />
                    <img src={d.doctor} className="user" alt="2" />
                    <div className="popular-content">
                        <div className="review d-flex">
                            <span className='doctorLink'>
                                <span>{d.spel}</span>,
                                <span>{d.spelname}</span>
                            </span>
                            <div className="star">
                                <ul>
                                    <li><i className='bx bxs-star'></i></li>
                                    <li><i className='bx bxs-star'></i></li>
                                    <li><i className='bx bxs-star'></i></li>
                                    <li><i className='bx bxs-star'></i></li>
                                    <li><i className='bx bxs-star'></i></li>
                                </ul>
                            </div>
                        </div>
                        <h3>{d.field}</h3>
                        <p>
                            <img src={icon} alt="3" /> {d.address}
                        </p>
                    </div>
                </motion.div>
                </Link>
                ))}
                
                
            </Slider>
        </div>
    </div>
  )
}



const data = [
    
    {   LinkTo: '/',
        img: require('../../Assets/images/popular/services/8.png'),
        doctor: require('../../Assets/images/popular/Maam.png'),
        spel: "Doctor",
        spelname: " Neelam Bhise",
        field: "IVF",
        address: "IUI initially for blocked fallopian tubes, now treats endometriosis, oligospermia. IVF evolved with refined techniques, criteria, and preparation, enhancing success over 30 years."
    },
    {
        LinkTo: '/',
        img: require('../../Assets/images/popular/services/6.png'),
        doctor: require('../../Assets/images/popular/Maam.png'),
        spel: "Doctor",
        spelname: " Neelam Bhise",
        field: "ICSI",
        address: "In the late 50s, gamete micromanipulation began. SUZI birth in Rome, 1990, by Simon Fishel's team. Gianpiero Palermo developed the technique further in 1991 at Vrije Universiteit Brussel."
    },
    {
        LinkTo: '/',
        img: require('../../Assets/images/popular/services/9.png'),
        doctor: require('../../Assets/images/popular/Maam.png'),
        spel: "Doctor",
        spelname: " Neelam Bhise",
        field: "IUI",
        address: "IUI is the initial infertility treatment, beneficial for male and female factors. Artificial insemination history dates to 1884, with Iowa's pioneering sperm bank in the 1920s."
    },
    {
        LinkTo: '/',
        img: require('../../Assets/images/popular/services/7.png'),
        doctor: require('../../Assets/images/popular/Maam.png'),
        spel: "Doctor",
        spelname: " Neelam Bhise",
        field: "PRP",
        address: "PRP prepared from autologous blood, infused 0.5-1ml into uterine cavity. Progesterone support followed. Successful implantation observed in _ pts. MACS improves sperm samples for AI or IVF."
    },
    {
        LinkTo: '/',
        img: require('../../Assets/images/popular/services/5.jpg'),
        doctor: require('../../Assets/images/popular/Maam.png'),
        spel: "Doctor",
        spelname: " Neelam Bhise",
        field: "Embryo Freezing",
        address: "A fertility specialist assesses ovarian reserve with blood tests and ultrasound to estimate oocyte yield and determine medication dosage for hormonal ovarian stimulation."
    }
]

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", borderRadius: "50%", padding: "19px" }}
        onClick={onClick}
      >
        <i class="bx bx-chevron-right" style={{ color: "#333" }}></i>
      </div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", borderRadius: "50%", padding: "19px"}}
        onClick={onClick}
      >
        <i class="bx bx-chevron-left" style={{ color: "#333" }}></i>
      </div>
    );
  }