import React from 'react'
import { Homepage } from '../Components/Homepage/Homepage'
import { OurServices } from '../Components/OurServices/OurServices'
// import { Counter } from '../Components/Counter/Counter'
// import { TabComp } from '../Components/Homepage/TabComp'
import { VideoSection } from '../Components/Homepage/VideoSection'
// import { Testimonals } from '../Components/Homepage/Testimonals'
import GoogleReviews from '../Components/Homepage/GoogleReviews'
import WhyChooseUs from '../Components/Homepage/WhyChooseUs'
// import Homepage1 from '../Components/Homepage/Homepage1'
import Transition from '../Components/Transition'
import Counter2 from '../Components/Counter/Counter2'
const Home = () => {
  return (
    <>
    <Homepage/>
    {/* <Homepage1/> */}
    <OurServices/>
    {/* <Counter/> */}
    <Counter2/>
    {/* <TabComp/> */}
    {/* <Testimonals/> */}
    <WhyChooseUs />
    <GoogleReviews/>
    <VideoSection/>

    </>
  )
}

export default Transition(Home)