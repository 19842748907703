import React from 'react'
import { AboutInnerBanner } from '../../Components/Common/InnerBanner/AboutInnerBanner'
import AboutContent from '../../Components/Common/AboutContent/AboutContent'
import AboutInnBanContent from '../../Components/Common/InnerBanner/AboutInnBanContent'
import AboutInfo from '../../Components/Common/AboutContent/AboutInfo'
import Transition from '../../Components/Transition'

const AffordableTreat = () => {
  const {image, heading, pageName} = AboutInnBanContent.AffordableTreat;
  const {bg, doctor, title, data} = AboutInfo.AffordableTreat; 
  return (
    <>
      <AboutInnerBanner image={image} heading={heading} pageName={pageName} />
      <AboutContent bg={bg} doctor={doctor} title={title} data={data} />
    </>
  )
}

export default Transition(AffordableTreat) 