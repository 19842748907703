import React from 'react'

const DbDevide = ({dbHeading, dbImg, DBdata}) => {
  return (
            <div className="bd-divide">
                <h4 className='pb-2'>{dbHeading}</h4>
                <div className="row align-items-center">
                    <div className="col-lg-6">
                    <img src={dbImg} alt="bg" className='rounded' />
                    </div>
                    <div className="col-lg-6">
                    <div className="bd-list">
                        <ul>
                            {DBdata.map((d, index)=>(
                                <li key={index}>
                                        <i className="bx bx-right-arrow-circle" />
                                        {d.dbTxt}
                                </li>
                            ))}
                        </ul>
                    </div>
                    </div>
                </div>
            </div>

  )
}

export default DbDevide