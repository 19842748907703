
const SDevideData = {
    IVF_ICSI:{
        Wife:{
            sdHeading: "The Preliminary Investigations are For Wife",
            sdImg: require('../../../Assets/images/programe/1.png'),
            SDdata:[
                {
                    dbTxt:'Hysteroscopy & measurement of Uterocervical length.'
                },
                {
                    dbTxt:'Ultrasound Examination of uterus and ovaries. (To exclude uterine pathology and cyst in ovaries)'
                },
                {
                    dbTxt:'Hormonal Profile (TSH, Prolactin, FSH & LH on 2 nd or 3 rd day of the period).'
                },
                {
                    dbTxt:'CBC, ESR, Blood VDRL, Blood Sugar PP, Blood group and Rh factor, HbsAg (Australia Antigen), HIV, HCV antibodies, Bleeding Time & Clotting Time, X-Ray chest'
                },
            ]
        },
        Husband :{
            sdHeading2: "The Preliminary Investigations are For Husband ",
            sdImg2: require('../../../Assets/images/programe/2.png'),
            SDdata2:[
                {
                    dbTxt:'Semen analysis.'
                },
                {
                    dbTxt:'Semen culture and antibiotic sensitivity test'
                },
                {
                    dbTxt:'Antisperm Antibody test for husband and wife'
                },
                {
                    dbTxt:'Sperm Survival test and semen harvesting'
                },
                {
                    dbTxt:'Blood for Australia Antigen, HIV Antibodies, HCV Antibodies'
                },
               
            ]
        },
    },
    IUI: {
        male:{
            sdHeading: "For Male",
            sdImg: require('../../../Assets/images/programe/3.png'),
            SDdata:[
                {
                    dbTxt:'Oligoasthenoteratozoospermia i.e. men with low sperm count, or less motile sperm in the ejaculate, or having many abnormal sperm. In our experience we have found that if the total motile sperm concentration after sperm wash is less than 5 million then the success rate is less.'
                },
                {
                    dbTxt:'Sexual or ejaculatory dysfunction where semen is collected using vibrator or through electro ejaculation.'
                },
                {
                    dbTxt:'Retrograde ejaculation, where semen enters the bladder after orgasm, instead of ejaculating out through penis.'
                },
                {
                    dbTxt:'Immunological factors like autoantibodies and sperm agglutination.'
                },
                {
                    dbTxt:'Men with highly viscous semen for prolong time, which restricts sperm movement deposited in the cervix under natural circumstances.'
                },
                {
                    dbTxt:'Donor sperm insemination'
                },
            ]
        },
        female:{
            sdHeading1: "For Female",
            sdImg1: require('../../../Assets/images/programe/4.png'),
            SDdata1:[
                {
                    dbTxt:'Anatomical defects of the reproductive tract, where direct coitus is not possible'
                },
                {
                    dbTxt:'Psychological sexual dysfunction - dysparuenia, vaginismus.'
                },
                {
                    dbTxt:'Cervical factors i.e. poor sperm-mucus interaction, failed post-coital test, antisperm antibodies.'
                },
                {
                    dbTxt:'Ovulatory dysfunction'
                },
                {
                    dbTxt:'Unexplained infertility'
                },
                {
                    dbTxt:'Minimal endometriosis'
                },
                
            ]
        },
        Preparation: {
            sdHeading2: "For Female",
            sdImg2: require('../../../Assets/images/programe/5.png'),
            SDdata2:[
                {
                    dbTxt:'Anatomical defects of the reproductive tract, where direct coitus is not possible'
                },
                {
                    dbTxt:'Psychological sexual dysfunction - dysparuenia, vaginismus.'
                },
                {
                    dbTxt:'Cervical factors i.e. poor sperm-mucus interaction, failed post-coital test, antisperm antibodies.'
                },
                {
                    dbTxt:'Ovulatory dysfunction'
                },
                {
                    dbTxt:'Unexplained infertility'
                },
                {
                    dbTxt:'Minimal endometriosis'
                },
                
            ]
        }
    },

    ForeignPatientCare: {
            sdHeading: "We are the One Stop Solution for Fertility Problems, because we provide",
            sdImg: require('../../../Assets/images/programe/6.png'),
            SDdata:[
                {
                    dbTxt:'A comprehensive evidence base practice'
                },
                {
                    dbTxt:'Genuine consultation'
                },
                {
                    dbTxt:'Independent advisory committees'
                },
                {
                    dbTxt:'Transparent process and decision making'
                },
                {
                    dbTxt:'Better work Culture & Organizational design'
                },
                {
                    dbTxt:"Clinical and patient's input"
                },
                {
                    dbTxt:'Regular review of previous cases'
                },
                {
                    dbTxt:'Effective implementation'
                },
            ]
    },
    LaserAssistedHatching: {
        sdHeading: "The most common indications are",
            sdImg: require('../../../Assets/images/programe/7.png'),
            SDdata:[
                {
                    dbTxt:'Advanced maternal age (>37 years old).'
                },
                {
                    dbTxt:'Thick and/or abnormal egg shell.'
                },
                {
                    dbTxt:'Embryos exhibiting excessive fragmentation.'
                },
                {
                    dbTxt:'Patients with elevated Day 3 follicle stimulating hormone (FSH).'
                },
                {
                    dbTxt:'Poor quality/slow developing embryos.'
                },
                {
                    dbTxt:'Patient with 2 or more previous failed IVF cycles.'
                },
                
            ]
    },

    OocyteFreezing: {
        sdHeading: "Cryopreservation of the oocytes can be considered for a variety of reasons",
            sdImg: require('../../../Assets/images/programe/8.png'),
            SDdata:[
                {
                    dbTxt:'Women with cancer requiring chemotherapy and/or pelvic radiation therapy that may affect fertility.'
                },
                {
                    dbTxt:'Surgery that may cause damage to the ovaries.'
                },
                {
                    dbTxt:'Risk of premature ovarian failure because of chromosomal abnormalities (e.g. Turner syndrome, fragile X syndrome), or family history of early menopause.'
                },
                {
                    dbTxt:'Ovarian disease with risk of damage to the ovaries.'
                },
                {
                    dbTxt:'Genetic mutations requiring removing the ovaries (e.g. BRCA mutation).'
                },
                {
                    dbTxt:'Fertility preservation for social or personal reasons to delay childbearing.'
                },
                
            ]
    }
}

export default SDevideData