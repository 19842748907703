import React from 'react'

const SDevide = ({sdHeading, sdImg, SDdata}) => {
  return (
            <div className="bd-divide">
                <h4 className='pb-2'>{sdHeading}</h4>
                <div className="row">
                    <div className="col-lg-4">
                    <img src={sdImg} alt="SD" className='rounded' />
                    </div>
                    <div className="col-lg-8">
                        <div className="bd-list">
                            <ul>
                                {SDdata.map((d, index)=>(
                                    <li key={index}>
                                            <i className="bx bx-right-arrow-alt" />
                                            {d.dbTxt}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
  )
}

export default SDevide