import React, { useRef } from 'react'
import { AboutInnerBanner } from '../Components/Common/InnerBanner/AboutInnerBanner'
import AboutInnBanContent from '../Components/Common/InnerBanner/AboutInnBanContent'
import { Link } from 'react-router-dom';
import cOne from "../Assets/images/contact/contact-1.png";
import CTwo from "../Assets/images/contact/contact-2.png";
import cThree from "../Assets/images/contact/contact-3.png";
import Transition from '../Components/Transition';
import Swal from 'sweetalert2'
import emailjs from '@emailjs/browser';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const Contact = () => {
    const { image, heading, pageName } = AboutInnBanContent.Contact;

    const formik = useFormik({
        initialValues: {
            fname: '',
            email: '',
            phone: '',
            subject: '',
            message: ''
        },
        validationSchema: Yup.object({
            fname: Yup.string().required("Name is required"),
            email: Yup.string().email("Invalid email address").required("Email is required"),
            phone: Yup.string().matches(/^[0-9]{10}$/, "Enter a valid 10-digit phone number").required("Phone number is required"),
            subject: Yup.string().required("Subject is required"),
            message: Yup.string().required("Message is required")
        }),
        onSubmit: (values, { resetForm }) => {
            //alert(values);
            resetForm();
            sendEmail();
        }
    });
    const form = useRef();

    const sendEmail = (e) => {
        emailjs.sendForm('service_fse8fdt', 'template_x0swfnn', form.current, '5nmVfaLLLRUevaijH')
            .then((result) => {
                console.log(result.text);
                Swal.fire({
                    title: "Form Submitted Successfully!",
                    text: "We will get back to you soon.",
                    icon: "success"
                });
            }, (error) => {
                console.log(error.text)
                Swal.fire({
                    title: "Submission Failed!",
                    text: "There was an error submitting your form. Please try again later.",
                    icon: "error"
                });
            });
    }

    return (
        <>
            <AboutInnerBanner image={image} heading={heading} pageName={pageName} />

            <div className="appointment-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="contact-us-card">
                                <div className="cus-items d-flex">
                                    <div className="contact-last-img col-md-4">
                                        <img src={cOne} alt="1" />
                                    </div>
                                    <div className="cus-info col-md-8">
                                        <h6>Contact Us Free</h6>
                                        <Link className="cus-a">022 - 69770000</Link>
                                        <Link>+91 8655 00 6567</Link>
                                    </div>
                                </div>

                                <div className="cus-items d-flex">
                                    <div className="contact-last-img col-md-4">
                                        <img src={CTwo} alt="2" />
                                    </div>
                                    <div className="cus-info col-md-8">
                                        <h6>Email Us</h6>
                                        <Link className="cus-a">help@acmefertility.com</Link>
                                        <Link>contact@acmefertility.com</Link>
                                    </div>
                                </div>

                                <div className="cus-items d-flex cus-items2">
                                    <div className="contact-last-img col-md-4">
                                        <img src={cThree} alt="3" />
                                    </div>
                                    <div className="cus-info col-md-8">
                                        <h6>Hospital Address</h6>
                                        <p className="cus-p">402, 4th Floor, Signature Business Park, Postal Colony Road, Near Chembur Mono Rail Station, Chembur, Mumbai - 400071</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="appointment-from">
                                <span>Get In Touch</span>
                                <h3>Contact Us</h3>
                                <form ref={form} onSubmit={formik.handleSubmit} id="contactForm" className="contact-from">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input type="text" name='fname' className="form-control" placeholder="Name"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.fname}
                                                />
                                                <div className='error'>
                                                    <div className="with-errors">
                                                        {formik.touched.fname && formik.errors.fname ? formik.errors.fname : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input type="email" name='email' className="form-control" placeholder="Email"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.email}
                                                />
                                                <div className='error'>
                                                    <div className="with-errors">
                                                        {formik.touched.email && formik.errors.email ? formik.errors.email : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input type="number" name='phone' className="form-control" placeholder="Phone"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.phone}
                                                />
                                                <div className="error">
                                                    <div className= "with-errors">
                                                        {formik.touched.phone && formik.errors.phone ? formik.errors.phone : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input type="text" name='subject' className="form-control" placeholder="Subject"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.subject}
                                                />
                                                <div className="error">
                                                    <div className= "with-errors">
                                                        {formik.touched.subject && formik.errors.subject ? formik.errors.subject : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="form-group">
                                                <textarea className="form-control" name='message' id="message" cols="30" rows="8" placeholder="Message"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.message}
                                                ></textarea>
                                                <div className="error">
                                                    <div className= "with-errors">
                                                        {formik.touched.message && formik.errors.message ? formik.errors.message : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <button type="submit" className="common-btn style1 disabled">Submit Now</button>
                                            <div id="msgSubmit" className="h3 text-center hidden"></div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Transition(Contact)
