import React from 'react'
import TopContextData from './Common/TopContextData'
import TopContext from './Common/TopContext';
import AllDetails from '../Common/Services/AllDetails';
import AllDetailsData from '../Common/Services/AllDetailsData';

const PRP = () => { 
    const {greenHeading, head, video, pOne} = TopContextData.PRP;
    const {AllDData} = AllDetailsData.PRP;
  return (
    <>
    <TopContext greenHeading={greenHeading} head={head} video={video} pOne={pOne} />
    <AllDetails AllDData={AllDData} />
    </>
  )
}

export default PRP