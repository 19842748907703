import React from 'react'

const DbList = ({dlHeading, Dldata}) => {
  return (
    <div className="bd-divide">
        <h4 className='pb-2'>{dlHeading}</h4>
        <div className="row align-items-center">
            <div className="col-lg-12">
            <div className="bd-list">
                <ul>
                    {Dldata.map((d, index)=>(
                        <li key={index}>
                                <i className="bx bx-log-in-circle" />
                                {d.dlTxt}
                        </li>
                    ))}
                </ul>
            </div>
            </div>
        </div>
    </div>
  )
}

export default DbList