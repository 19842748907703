
const TopContextData = {
    IVF:{
        greenHeading: "IVF",
        head: "Best IVF Centre in Mumbai", 
        video: require('../../../Assets/images/video/ivf.mp4'),
        pOne: "At ACME FERTILITY - we have a team of best IVF specialists in Mumbai, dedicated to providing the highest quality of health care. The aim of ACME FERTILITY is to achieve the highest possible standards in all aspect of medical practice - a personalized, clinical approach that aims to minimize medical and surgical intervention, yet offers you the best IVF treatment in Mumbai and option available. We encourage you to learn as much as you can about the IVF program at best IVF centre in Mumbai.",
        pTwo:"This section of the Web site offers an overview of medications, procedures, success rates and financial issues related to IVF. You will also have the opportunity to tour our state-of-the-art medical facilities, post your queries you may have related to infertility. Get in touch with us to know the details about IVF cost in Mumbai."
    },
    ICSI:{
        greenHeading1: "ICSI",
        head1: "IVF And ICSI Best IVF Centre in Mumbai", 
        video1: require('../../../Assets/images/video/ICSI.mp4'),
        pOne1: "The first gamete micromanipulation techniques date back to the late 50's. In Rome in 1990 there was the first birth by injection of the sperm into the perivitelline space (SUZI) obtained by the team Simon Fishel, Severino Antinori and Franco Lisi The technique was developed by Gianpiero Palermo in 1991 at the Vrije Universiteit Brussel, in the Center for Reproductive Medicine headed by Paul Devroey and Andre Van Steirteghem. The first American baby was conceived with the technique At Reproductive Biology Associates (RBA) in Atlanta, Georgia in 1992 under the direction of Michael Tucker. ",
        pTwo1:"The first large experience with the technique in the United States was published by Joseph D. Schulman and colleagues at Genetics and IVF Institute in 1995 Intracytoplasmic sperm injection ICSI -This is a well-established technique that enables an embryologist to manipulate both male and female gametes outside the body to maximize the probability of fertilization."
    },
    PRP:{
        greenHeading: "PRP",
        head: "Platelet-Rich Plasma", 
        video: require('../../../Assets/images/video/prp.mp4'),
        pOne: "We tried to evaluate the effectiveness of autologous PRP in the therapy of infertile women with thin endometrium (or = 7mm) and asherman's syndrome.",
       
    },
    IUI:{
        greenHeading: "IUI",
        head: "Intrauterine insemination or IUI", 
        video: require('../../../Assets/images/video/iui.mp4'),
        pOne: "As commonly known, can be considered as the first line of treatment for infertility. IUI can be useful for both male and/or female factor related infertility. The first reported case of artificial insemination by donor occurred in 1884. The sperm bank was developed in Iowa starting in the 1920s in research conducted by University of Iowa medical school researchers Jerome Sherman and Raymond Bunge. In the 1980s, direct intraperitoneal insemination (DIPI) was occasionally used, where doctors injected sperm into the lower abdomen through a surgical hole or incision, with the intention of letting them find the oocyte at the ovary or after entering the genital tract through the ostium of the fallopian tube.",
    },
    EmbryoTransfer:{
        greenHeading: "vitro fertilization (IVF)",
        head: "Embryo Transfer", 
        video: require('../../../Assets/images/video/embroTransfer.mp4'),
        pOne: "Since its debut in 1978, in vitro fertilization (IVF) has undergone significant advancements, transforming into a safe, efficient, and accessible procedure. With over 2 million IVF-born children to date, ongoing refinements in laboratory techniques and clinical practices continue to enhance success rates. At ACME FERTILITY clinic & IVF center, we prioritize technological innovation and clinical excellence to maximize success for every patient we serve in today's rapidly evolving world.",
    },
    AssistedHeching:{
        greenHeading: "AZH",
        head: "Assisted Heching", 
        video: require('../../../Assets/images/video/AssistedHeching.mp4'),
        pOne: "Assisted zona hatching (AZH) is an assisted reproductive technique where a small hole is created in the zona pellucida, aiding embryo hatching. This procedure, often done with micromanipulation, helps embryos implant in the uterus. Normally, the embryo hatches from the zona pellucida, but in cases of infertility, a hardened zona may hinder this process. AZH involves thinning or puncturing the zona with acid, laser, or mechanical methods. This enhances the embryo's ability to implant, particularly beneficial for cases like thick or hardened shells.",
    },
    PGS: {
        greenHeading: "PGS",
        head: "Pre-Implantation Genetic Screening",
        video: require('../../../Assets/images/video/pgs.mp4'),
        pOne: "PGS (also known as aneuploidy screening) involves checking the chromosomes of embryos conceived by IVF or ICSI for common abnormalities. Chromosomal abnormalities are a major cause of the failure of embryos to implant, and of miscarriages. They can also cause conditions such as Down's syndrome.",
    },
    MACS: {
        greenHeading: "MACS",
        head: "Magnetic Activated cell Sorting ",
        video: require('../../../Assets/images/video/MACS.mp4'),
        pOne: "Capsule Sperm selection using magnetic activated cell sorting (MACS) is an efficient method to select functional sperm and improves pregnancy rates when used to complement standard sperm selection methods in ART."
    },
    GamateDonars: {
        greenHeading: "Oocyte Donation Program",
        head: "Gamate Donars ",
        video: require('../../../Assets/images/video/GamateDonars.mp4'),
        pOne: "EGG DONATION The Oocyte Donation Program is an innovative alternative for infertile couples that make it possible for infertile women, who for medical reasons do not have fertilizable eggs to carry and give birth to a child. The embryo is then transferred to the mother's uterus. This allows the woman to carry the child and experience pregnancy, birth and breast feeding i.e the joy of motherhood. At ACME FERTILITY we strictly adhere to the norms and work to excel in all aspects keeping patient's safety and future implications on priority."
    },
    SemenCrypto: {
        greenHeading: "semen",
        head: "Semen Cryopreservation",
        video: require('../../../Assets/images/video/SemenCrypto.mp4'),
        pOne: "Cryopreservation, notably for semen, involves cooling cells or tissues to sub-zero temperatures to halt enzymatic or chemical activity. ACME FERTILITY adheres to strict norms, prioritizing patient safety. Sperm preservation is crucial in IVF, especially when the male partner is unavailable during Ovum Pick up, or in cases of low sperm count, ejaculatory dysfunction, or upcoming chemotherapy. Our andrology section offers sperm cryopreservation, ensuring reliable storage at reasonable rates."
    },
    EmbryoCrypto: {
        greenHeading: "Embryo",
        head: "Embryo Cryopreservation",
        video: require('../../../Assets/images/video/EmbryoCrypto.mp4'),
        pOne: "Cryopreservation, essential for preserving biological material, is prominently used for semen. By cooling to sub-zero temperatures, enzymatic or chemical activity is halted, safeguarding the material. Cryoprotectants are often utilized to coat the material. At ACME FERTILITY, we prioritize patient safety and future implications, offering sperm cryopreservation services. This is vital for situations like unavailability of the male partner during Ovum Pick up, international travel, low sperm count, ejaculatory dysfunction, or impending chemotherapy. Our andrology section ensures secure storage of sperm at reasonable rates until needed."
    },
    ForeignPatientCare:{
        greenHeading: "world-class IVF",
        head: "Foreign Patient Care",
        video: require('../../../Assets/images/video/ForeignPatientCare.mp4'),
        pOne: "ACME FERTILITY in Mumbai offers world-class IVF and surrogacy services, catering to international patients seeking high-quality treatment at a fraction of the cost compared to the US or Europe. Located conveniently in Chembur, Mumbai, with easy access to major transportation hubs, the clinic boasts state-of-the-art facilities and expert staff. Special legal assistance ensures smooth procedures for foreign patients. ACME FERTILITY prioritizes patient comfort and stress-free experiences, emphasizing a supportive environment throughout treatment. ACME FERTILITY: Leading IVF Clinic in India, Offering Cutting-Edge Infertility Treatments and Internationally Recognized Medical Care."
    },
    SET:{
        greenHeading: "SET",
        head: "Single Embryo Transfer",
        video: require('../../../Assets/images/video/SET.mp4'),
        pOne: "It has long been standard practice in the field of in vitro fertilization (IVF) to transfer multiple embryos to improve thechances that at least one viable pregnancy will result. However, the process of multiple transfers presents increased seriousrisks and complications to both mothers and infants when twin, triplet and higher-order pregnancies occur. These complicationsrange from preeclampsia and gestational diabetes to higher rates of C-section and psychological issues for the moms, and toprematurity, birth complications, long-term learning disabilities and developmental problems in their offspring. In one studyconducted by the Centers for Disease Control (CDC), half of the infants from multiple pregnancies had adverse outcomes.",
    },
    LaserAssistedHatching:{
        greenHeading: "Latest laboratory procedure ",
        head: "Laser Assisted Hatching",
        video: require('../../../Assets/images/video/LaserAssistedHatching.mp4'),
        pOne: "Assisted Hatching is a laboratory procedure performed mostly on cleavage stage embryos (Day 3 of In Vitro development) before being transferred back to the patient's womb.During the Assisted Hatching procedure, a small opening is made in the outer shell of the embryo, called the zona pellucida. In order to assist the embryo in breaking out of this shell to facilitate the implantation process.During Laser Assisted Hatching, the outer shell of the embryo is breached by firing a few laser pulses. The laser is fired far away from the cells of the embryo so that the cells are not damaged. This process only takes a few seconds to complete per embryo minimizing the time each embryo is exposed outside of the incubator.",
    },
    OocyteFreezing:{
        greenHeading: "oocyte cryopreservation",
        head: "Oocyte Freezing/Egg Freezing",
        video: require('../../../Assets/images/video/OocyteFreezing.mp4'),
        pOne: "Egg Freezing (oocyte cryopreservation) What is Egg Freezing? Egg freezing, or oocyte cryopreservation, is a process in which a woman's eggs (oocytes) are extracted, frozen and stored as a method to preserve reproductive potential in women of reproductive age.",
    },
    PICSI: {
        greenHeading: "PICSI",
        head: "Physiological Intra-Cytoplasmic Sperm Injection",
        video: require('../../../Assets/images/video/PICSI.mp4'),
        pOne: "PICSI is a technique that simulates the natural selection of mature sperm. The principle of this method is the cultivation of mature sperm in a specially treated dish, to which a gel containing hyaluronan is applied. The sperm selected are then used for micro-manipulative fertilisation.",
    }
}

export default TopContextData