import React from 'react'
import TopContext from './Common/TopContext'
import TopContextData from './Common/TopContextData'
import DbListData from '../Common/Services/DbListData';
import DbList from '../Common/Services/DbList';
import AllDetailsData from '../Common/Services/AllDetailsData';
import AllDetails from '../Common/Services/AllDetails';

const GamateDonars = () => {
    const {greenHeading, head, video, pOne} = TopContextData.GamateDonars;
    const {dlHeading, Dldata} = DbListData.GamateDonars.first;
    const {AllDData} = AllDetailsData.GamateDonars;
  return ( 
    <>
    <TopContext greenHeading={greenHeading} head={head} video={video} pOne={pOne} />
    <DbList dlHeading={dlHeading} Dldata={Dldata} />
    <AllDetails AllDData={AllDData} />
    </>
  )
}

export default GamateDonars