import { Footer } from "./Components/HeaderFooter/Footer";
// import { Navbar } from "./Components/HeaderFooter/Navbar";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./Pages/Home";
import AdvancedTechnology from "./Pages/About/AdvancedTechnology";
import AffordableTreat from "./Pages/About/AffordableTreat";
import Introduction from "./Pages/About/Introduction";
import PersonalizedCare from "./Pages/About/PersonalizedCare";
import QualityExpertise from "./Pages/About/QualityExpertise";
import RightEnvironment from "./Pages/About/RightEnvironment";
import Appointment from "./Pages/Appointment";
import Contact from "./Pages/Contact";
import Doctor from "./Pages/Doctor";
import LaparoscopyHysteroscopy from "./Pages/Services/LaparoscopyHysteroscopy";
import WomenHealthCare from "./Pages/Services/WomenHealthCare";
import FertilityCounselling from "./Pages/Services/FertilityCounselling";
import HormonalAssays from "./Pages/Services/HormonalAssays";
import FollicularMonitoring from "./Pages/Services/FollicularMonitoring";
import MaleInfertility from "./Pages/Services/MaleInfertility";
import ObstetricCare from "./Pages/Services/ObstetricCare";
import UrinaryIncontinenceManagement from "./Pages/Services/UrinaryIncontinenceManagement";
import FAQ from "./Pages/FAQ";
import Programe from "./Pages/Programe";
import Headroom from "react-headroom";
import ScrollToTop from "./Components/ScrollToTop";
import BackToTopButton from "./Components/BackToTopButton";
import { useEffect, useState } from "react";
import PreLoader from "./Components/PreLoader";
import { AnimatePresence } from "framer-motion";
 
import './responsive.scss';
import Navbar2 from "./Components/HeaderFooter/Navbar2";

function App() {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(()=>{
      setTimeout(()=>{
        setIsLoading(false)
      }, 2000)
    }, [])
  return (
      isLoading ? (<PreLoader/>) :
     ( 
     
     <>
      <ScrollToTop/>
      <Headroom>
        {/* <Navbar/> */}
        <Navbar2/>
      </Headroom>
          <AnimatePresence mode='wait'>
          <Routes basename = "/" location={location} key={location.pathname}>
            <Route path="/" index element={<Home/>} />

            <Route path="/AdvancedTechnology" element={<AdvancedTechnology/>} />
            <Route path="/AffordableTreatment" element={<AffordableTreat/>} />
            <Route path="/Introduction" element={<Introduction/>} />
            <Route path="/PersonalizedCare" element={<PersonalizedCare/>} />
            <Route path="/QualityExpertise" element={<QualityExpertise/>} />
            <Route path="/RightEnvironment" element={<RightEnvironment/>} />

            <Route path="/Appointment" element={<Appointment/>} />
            <Route path="/Contact" element={<Contact/>} />
            <Route path="/Doctor" element= {<Doctor/>} />
            
            <Route path="/LaparoscopyHysteroscopy" element= {<LaparoscopyHysteroscopy/>} />
            <Route path="/WomenHealthCare" element= {<WomenHealthCare/>} />
            <Route path="/FertilityCounselling" element= {<FertilityCounselling/>} />
            <Route path="/HormonalAssays" element= {<HormonalAssays/>} />
            <Route path="/FollicularMonitoring" element= {<FollicularMonitoring/>} />
            <Route path="/MaleInfertility" element= {<MaleInfertility/>} />
            <Route path="/ObstetricCare" element= {<ObstetricCare/>} />
            <Route path="/UrinaryIncontinenceManagement" element= {<UrinaryIncontinenceManagement/>} />

            <Route path="/FAQ" element={<FAQ/>} />

            <Route path="/Programe" element={<Programe/>} />

          </Routes>
          </AnimatePresence>
        <Footer/>
      <BackToTopButton/>
      </>
     )
  );
}

export default App;
