import React from 'react'

const AllDetails = ({AllDData}) => {
  return (
    <>
    <div className='blog-card test-details-card'>
        {AllDData.map((d) => (
            <div class="all-details all-details-bd">
            <h4>{d.title}</h4>
            <p>{d.para}</p>
        </div>  
        ))}
    </div>
                           
    </>
  )
}

export default AllDetails