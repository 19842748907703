
const AllDetailsData = {
    LaparoscopyHysteroscopy:{
        AllDData:[
            {
                title: 'Indications for gestational surrogacy',
                para: "The key element in laparoscopic surgery is the use of a laparoscope. a digital laparoscope where the charge-coupled device is placed at the end of the laparoscope, Also attached is a fiber optic cable system connected to a 'cold' light source (halogen or xenonto )illuminate the operative field, inserted through a 5 mm or 10 mm cannula or trocar to view the operative field. The abdomen is usually insufflated, or essentially blown up like a balloon, with carbon dioxide gas. This elevates the abdominal wall above the internal organs like a dome to create a working and viewing space. CO2 is used because it is common to the human body and can be absorbed by tissue and removed by the respiratory system. It is also non-flammable, which is important because electrosurgical devices are commonly used in laparoscopic procedures."
            }
        ]
    },

    FertilityCounselling:{
        AllDData:[
            {
                title: 'As per World Health Organization defines infertility as',
                para: '"Infertility is Inability to conceive a child. A couple may be considered infertile if, after two years of regular sexual intercourse, without contraception, the woman has not become pregnant (and there is no other reason, such as breastfeeding or postpartum amenorrhea). Primary infertility is infertility in a couple who have never had a child. Secondary infertility is failure to conceive following a previous pregnancy. Infertility may be caused by infection in the man or woman, but often there is no obvious underlying cause." A couple that tries unsuccessfully to have a child after a certain period of time (often a short period, but definitions vary) is sometimes said to be subfertility, meaning less fertile than a typical couple. Both infertility and subfertility are defined as the inability to conceive after a certain period of time (the length of which vary), so often the two terms overlap.'
            }
        ]
    },

    MaleInfertility: {
        up: {
            AllDData: [
                {
                    title: "Abnormal semen analysis",
                    para: "An abnormal sperm analysis is repeated first for verification. Typically, the male partner is evaluated by our andrologist, then according to physical examination and semen parameters fertility management is done."
                },
                {
                    title: "Azoospermia",
                    para: "Azoospermia is a condition, where there is no sperm in the initial fluid. In most cases, it is caused by either primary testicular failure or hormonal, chromosomal or obstructive abnormalities. Patient need hormonal, urologic, genetic or ultrasonographic examinations to further evaluate the problem. At ACME Fertility all these evaluations are done under one roof in accordance to the world standards."
                },
                
            ]
        },
    
        down: {
            AllDData2: [
                {
                    title: "Epididymal Aspiration",
                    para: "This surgical procedure is indicated for cases of congenital absence of the vas deferens (the long tube through which sperm travel during ejaculation), although some males with a vasectomy or failed (vasovasotomy) are also candidates. This technique allows sperm from the epididymis, a tubular structure attached to testicle that serves as a reservoir where sperm mature and are stored. Because very low numbers of sperm are obtained, it is in conjunction with IVF and intracytoplasmic sperm injection."
                },
                {
                    title: "Percutaneous Epididymal Sperm Aspiration (PESA)",
                    para: "PESA is indicated for men with irreparable obstruction resulting in Azoospermia (lack of or no sperm), congenital absence of the deferens or who have had vasectomies or failed vasectomy reversals. The procedure takes approximately 10 to 20 minutes unlike epididymal aspiration, and does not require a surgical incision. A small needle is passed directly into the head of the epididymis and fluid is aspirated. Next, the IVF laboratory team retrieves the cells from the fluid and prepares them for ICSI because of the amount secured."
                },
                {
                    title: "Intracytoplasmic Sperm Injection (ICSI)",
                    para: "Assisted Microfertilization - Microsurgical fertilization, a form of micromanipulation, can be indicated in case of severe male factor, where conditions such as abnormal sperm movement or low sperm numbers make it impossible for sperms to penetrate and fertilize the egg. One of the methods to assist fertilization is Intracytoplasmic Sperm Injection (ICSI), ICSI involves, the use of very fine instruments to first pick up sperm & then injecting a single sperm directly into the cytoplasm of the egg. The cytoplasm is the area outside the nuclear cell of the oocyte and contains the physical elements of reproduction. ICSI also works for cases of unexplained non-fertilization with conventional IVF."
                },
                {
                    title: "Testicular Tissue Sperm Extraction (TESE)",
                    para: "Surgical removal of a portion of the testes for patients that are good candidates for PESA because of absence of the vas deferens. In the andrology laboratory, tissue is homogenized (minced) & individual sperm is harvested for ICSI."
                },
            ]
        }
        
    },

    ObstetricCare: {
        up: {
            AllDData: [
                {
                    title: "Prenatal Examinations",
                    para: "At the initial antenatal care visit and with the aid of a special booking checklist the pregnant women become classified into either normal risk or high risk. Prenatal diagnosis or prenatal screening is testing for diseases or conditions in a fetus or embryo before it is born. Our obstetricians will monitor mother's health and prenatal development during pregnancy through series of regular check-ups."
                },
                {
                    title: "Physical examinations generally consist of",
                    para: "1. Collection of (mother's) medical history 2. Checking (mother's) blood pressure 3. (Mother's) height and weight 4.Pelvic examination 5. Doppler fetal heart rate monitoring 6. (Mother's) blood and urine tests 7. Discussion with caregiver"
                },
                {
                    title: "Ultrasound Obstetric ultrasounds",
                    para: "are most commonly performed during the second trimester at approximately week 20. Ultrasounds are considered relatively safe and have been used for over 35 years for monitoring pregnancy. ultrasounds are used to: 1. Diagnose pregnancy 2. Check for multiple fetuses 3. Assess possible risks to the mother (e.g., miscarriage, blighted ovum, ectopic pregnancy, or a molar pregnancy condition) 4. Check for fetal malformation (e.g., club foot, spina bifida, cleft palate, clenched fists) 5. Determine if an intrauterine growth retardation condition exists 6. Note the development of fetal body parts (e.g., heart, brain, liver, stomach, skull, other bones) 7. Check the amniotic fluid and umbilical cord for possible problems8. Determine due date (based on measurements and relative developmental progress)"
                },
            ]
        },

        down: {
            AllDData2: [
                {
                    title: "Investigations First trimester :",
                    para: "1. Complete blood count (CBC) 2. Blood type 3. General antibody screen (indirect Coombs test) for HDN 4. Rh D negative antenatal patients should receive RhoGam at 28 weeks to prevent Rh disease. 5. Rapid plasma reagin (RPR) to screen for syphilis 6. Rubella antibody screen 7. Hepatitis B surface antigen 8. Gonorrhea and Chlamydia culture 9. PPD for tuberculosis 10. Pap smear 11. Urinalysis and culture 12. HIV screen 13. Group B Streptococcus screen - will receive IV penicillin or ampicillin if positive. Genetic screening for downs syndrome (trisomy 21) and trisomy 18 the national standard in the India is rapidly evolving away from the AFP-Quad screen for downs syndrome- done typically in the second trimester at 16-18 weeks."
                },
                {
                    title: "Investigations second trimester:",
                    para: "1. MSAFP/quad. screen (four simultaneous blood tests) (maternal serum alpha-fetoprotein; inhibin; estriol; bHCG or free bHCG) - elevations, low numbers or odd patterns correlate with neural tube defect risk and increased risks of trisomy 18 or trisomy 21 2. Ultrasound either abdominal or transvaginal to assess cervix, placenta, fluid and baby 3. Amniocentesis is the national standard (in what country) for women over 35 or who reach 35 by mid pregnancy or who are at increased risk by family history or prior birth history."
                },
                {
                    title: "Investigations Third trimester :",
                    para: "1. Hematocrit (if low, mother will receive iron supplementation) 2. Glucose loading test (GLT) - screens for gestational diabetes; if > 140 mg/dL, a glucose tolerance test (GTT) is administered; a fasting glucose > 105 mg/dL suggests gestational diabetes."
                },
                {
                    title: "Antenatal Record :",
                    para: "On the first visit to us the pregnant woman is asked to carry out the antenatal record, which constitutes a medical history and physical examination. On subsequent visits, the gestational age (GA) is rechecked with each visit. The fetus is palpated by the obstetrician using Leopold maneuver to determine the position of the baby. Blood pressure should also be monitored, and may be up to 140/90 in normal pregnancies. High blood pressure indicates hypertension and possibly pre-eclampsia, if severe swelling (edema) and spilled protein in the urine are also present. Fetal screening is also used to help assess the viability of the fetus, as well as congenital problems. Genetic counseling is often offered for families who may be at an increased risk to have a child with a genetic condition. Amniocentesis, which is usually performed between 15 and 20 weeks,[10] to check for Down syndrome, other chromosome abnormalities or other conditions in the fetus, is sometimes offered to women who are at increased risk due to factors such as older age, previous affected pregnancies or family history. Even earlier than amniocentesis is performed, the mother may undergo the triple test, nuchal screening, nasal bone, alpha-fetoprotein screening, Chorionic villus sampling, and also to check for disorders such as Down Syndrome. Amniocentesis is a prenatal genetic screening of the fetus, which involves inserting a needle through the mother's abdominal wall and uterine wall. Amniocentesis is required only when there is very high risk of having chromosomally abnormal baby as a confirmatory test."
                },
                {
                    title: "Fetal assessments :",
                    para: "Obstetric ultrasonography is routinely used for dating the gestational age of a pregnancy from the size of the fetus, the most accurate dating being in first trimester before the growth of the fetus has been significantly influenced by other factors. Ultrasound is also used for detecting congenital anomalies (or other fetal anomalies) and determining the biophysical profiles (BPP), which are generally easier to detect in the second trimester when the fetal structures are larger and more developed. Specialized ultrasound equipment can also evaluate the blood flow velocity in the umbilical cord, looking to detect a decrease/absence/reversal or diastolic blood flow in the umbilical artery Other tools used for assessment include: 1. Fetal karyotype can be used for the screening of genetic diseases. This can be obtained via amniocentesis or chorionic villus sampling (CVS) 2. Fetal hematocrit for the assessment of fetal anemia, Rh isoimmunization, or hydrops can be determined by percutaneous umbilical blood sampling (PUBS) which is done by placing a needle through the abdomen into the uterus and taking a portion of the umbilical cord. 3. Fetal lung maturity is associated with how much surfactant the fetus is producing. Reduced production of surfactant indicates decreased lung maturity and is a high risk factor for infant respiratory distress syndrome. Typically a lecithin:sphingomyelin ratio greater than 1.5 is associated with increased lung maturity. 4. Nonstress test (NST) for fetal heart rate 5. Oxytocin challenge test"
                },
                {
                    title: "Complications & Emergencies :",
                    para: "The main emergencies include 1. Ectopic pregnancy is when an embryo implants in the Fallopian tube or (rarely) on the ovary or inside the peritoneal cavity. This may cause massive internal bleeding. 2. Pre-eclampsia is a disease which is defined by a combination of signs and symptoms that are related to maternal hypertension. The cause is unknown, and markers are being sought to predict its development from the earliest stages of pregnancy. Some unknown factors cause vascular damage in the endothelium, causing hypertension. If severe, it progresses to eclampsia, where a convulsions occur, which can be fatal. Preeclamptic patients with the HELLP syndrome show liver failure and disseminated intravascular coagulation (DIC). 3. Placental abruption where the patient can bleed to death if not managed appropriately. 4. Fetal distress where the fetus is getting compromised in the uterine environment. 5. Shoulder dystocia where one of the fetus shoulders becomes stuck during vaginal birth, especially in macrosomic babies of diabetic mother. 6. Obstetrical hemorrhage may be due to a number of factors such as placenta previa, uterine rupture or tears, uterine atony, retained placenta or placental fragments, or bleeding disorders. 7. Puerperal sepsis is a progressed infection of the uterus during or after labor."
                },
                {
                    title: "Intercurrent diseases:",
                    para: "In addition to complications of pregnancy that can arise, a pregnant woman may have intercurrent diseases, that is, other diseases or conditions (not directly caused by the pregnancy) that may become worse or be a potential risk to the pregnancy. 1. Diabetes mellitus and pregnancy deals with the interactions of diabetes mellitus (not restricted to gestational diabetes) and pregnancy. Risks for the child include miscarriage, growth restriction, growth acceleration, fetal obesity (macrosomia), polyhydramnios and birth defects 2. Systemic lupus erythematosus and pregnancy confers an increased rate of fetal death in utero and spontaneous abortion (miscarriage), as well as of neonatal lupus 3. Thyroid disease in pregnancy can, if uncorrected, cause adverse effects on fetal and maternal well-being. The deleterious effects of thyroid dysfunction can also extend beyond pregnancy and delivery to affect neurointellectual development in the early life of the child. Demand for thyroid hormones is increased during pregnancy which may cause a previously unnoticed thyroid disorder to worsen. 4. Hypercoagulability in pregnancy is the propensity of pregnant women to develop thrombosis (blood clots). Pregnancy itself is a factor of hypercoagulability (pregnancy-induced hypercoagulability), as a physiologically adaptive mechanism to prevent post partum bleeding. However, when combined with an additional underlying hypercoagulable states, the risk of thrombosis or embolism may become substantial."
                },
                {
                    title: "Childbirth :",
                    para: "Induction is a method of artificially or prematurely stimulating labour in a woman. Reasons to induce can include pre-eclampsia, placental malfunction, intrauterine growth retardation, and other various general medical conditions, such as renal disease. Induction may occur any time after 34 weeks of gestation if the risk to the fetus or mother is greater than the risk of delivering a premature fetus regardless of lung maturity. Induction may be achieved via several methods 1. Pessary of Prostin cream, prostaglandin E2 2. Intravaginal or oral administration of misoprostol 3. Cervical insertion of a 30-mL Foley catheter 4. Rupturing the amniotic membranes 5. Intravenous infusion of synthetic oxytocin (Pitocin or Syntocinon)"
                },
                {
                    title: "Labour :",
                    para: "During labor itself, the obstetrician may be called on to do a number of tasks. These tasks can include: 1. Monitor the progress of labor, by reviewing the nursing chart, performing vaginal examination, and assessing the trace produced by a fetal monitoring device (the cardiotocograph). 2. Accelerate the progress of labor by infusion of the hormone oxytocin 3. Provide pain relief, either by nitrous oxide, opiates, or by epidural anesthesia done by anaesthestists, an anesthesiologist, or a nurse anesthetist. 4. Surgically assisting labor, by forceps or the Ventouse (a suction cap applied to the fetus' head) 5. Caesarean section, if there is an associated risk with vaginal delivery, as such fetal or maternal compromise supported by evidence and literature."
                },
                {
                    title: "Post natal Care :",
                    para: "Postnatal care is care provided to the mother following parturition. A woman in the city like Mumbai who is delivering in a hospital may leave the hospital as soon as she is medically stable and chooses to leave, which can be as early as a few hours postpartum, though the average for spontaneous vaginal delivery (SVD) is 1-2 days, and the average caesarean section postnatal stay is 3-4 days. During this time the mother is monitored for bleeding, bowel and bladder function, and baby care. The infant's health is also monitored."
                },
                {
                    title: "Medical Termination of Pregnancy",
                    para: "The Medical Termination of Pregnancy (MTP) Act in India, enacted in 1971, governs abortion procedures. It allows for the termination of pregnancy under specified conditions, performed by qualified individuals at approved facilities. Abortion, defined as the removal of a fetus before viability, is regulated under this law, ensuring safe and legal procedures."
                },
                {
                    title: "Some of these qualifications are as follows",
                    para: "1. Women whose physical and/or mental health were endangered by the pregnancy 2. Women facing the birth of a potentially handicapped or malformed child 3. Rape 4. Pregnancies in unmarried girls under the age of eighteen with the consent of a guardian 5. Pregnancies in “lunatics” with the consent of a guardian 6. Pregnancies that are a result of failure in sterilisation. The length of the pregnancy must not exceed twenty weeks in order to qualify for an abortion."
                },
                {
                    title: "Methods",
                    para: "1. Medical - Medical abortions are those induced by abortifacient pharmaceuticals. The most common early first-trimester medical abortion regimens use methotrexate in combination with a prostaglandin analog up to 7 weeks gestation. Mifepristone-misoprostol combination regimens work faster and are more effective at later gestational ages than methotrexate-misoprostol combination regimens, and combination regimens are more effective than misoprostol alone. If medical abortion fails, surgical abortion must be used to complete the procedure. 2. Surgical - Up to 12 weeks' gestation, suction-aspiration or vacuum aspiration are the most common surgical methods of induced abortion. These techniques differ in the mechanism used to apply suction, in how early in pregnancy they can be used, and in whether cervical dilation is necessary. Dilation and curettage (D&C) the second most common method of surgical abortion, is a standard gynecological procedure performed for a variety of reasons, including examination of the uterine lining for possible malignancy, investigation of abnormal bleeding, and abortion. In the third trimester of pregnancy, abortion may be performed by induction of labor, or by hysterotomy."
                },
                {
                    title: "Contraindications due to Medical Reasons",
                    para: "1. Smoking > 35 years. 2. Anemia - hemoglobin < 8 gm % 3. Suspected /confirmed ectopic pregnancy / undiagnosed adnexal mass 4. Coagulopathy or women on anticoagulant therapy 5. Chronic adrenal failure or current use of systemic corticosteroids 6. Uncontrolled hypertension with bp >160/100mmhg 7. Cardio-vascular diseases such as angina, valvular disease, arrhythmia 8. Severe renal, liver or respiratory diseases 9. Glaucoma 10. Uncontrolled seizure disorder"
                },
            ]
        },
    },
    UrinaryIncontinenceManagement:{
        first:{
            AllDData:[
            {
                title: 'Checkups',
                para: 'At ACME fertility careful evaluation of the pattern of voiding and urine leakage is done so as to find the exact type of incontinence . Careful history taking is done to find other important points like straining and discomfort, use of drugs, recent surgery, and illness. The physical examination will focus on looking for signs of medical conditions causing incontinence, such as tumors that block the urinary tract, stool impaction, and poor reflexes or sensations, which may be evidence of a nerve-related cause.A test often performed is the measurement of bladder capacity and residual urine for evidence of poorly functioning bladder muscles.'
            }
        ]
        },
        second:{
            AllDData1:[
            {
                title: 'Checkups',
                para: 'At ACME fertility careful evaluation of the pattern of voiding and urine leakage is done so as to find the exact type of incontinence . Careful history taking is done to find other important points like straining and discomfort, use of drugs, recent surgery, and illness. The physical examination will focus on looking for signs of medical conditions causing incontinence, such as tumors that block the urinary tract, stool impaction, and poor reflexes or sensations, which may be evidence of a nerve-related cause.A test often performed is the measurement of bladder capacity and residual urine for evidence of poorly functioning bladder muscles.'
            }
        ]
        },
    },
    IVF_ICSI: {
        first: {
            AllDData:[
                {
                    title:'The IVF Program',
                    para: 'Many couples willing to have their own child are still unable to become pregnant after first line therapy such as ovulation induction, intrauterine insemination, or reproductive surgery. For these couples, the next logical step is to explore the Assisted Reproductive Technologies (ART) like In Vitro Fertilization (IVF) popularly known as Test Tube Baby.'
                }
            ]
        },
        second: {
            AllDData1:[
                {
                    title:'IVF Medications',
                    para: 'Many couples willing to have their own child are still unable to become pregnant after first line therapy such as ovulation induction, intrauterine insemination, or reproductive surgery. For these couples, the next logical step is to explore the Assisted Reproductive Technologies (ART) like In Vitro Fertilization (IVF) popularly known as Test Tube Baby.'
                },
                {
                    title:'Indications for ART',
                    para: 'It is necessary to take certain medications during the IVF cycle in order to prepare the body for the treatment. The instructions for each medication vary from patient to patient. The medical team at ACME FERTILITY will analyze your case closely to determine which medications to use, what dosage to take, when to administer the medications and how long to take them.'
                }
            ]
        },
        third: {
            AllDData2:[
                {
                    title: "1. Initial Consultation:",
                    para: "The couple should bring along their records of infertility workup that they possess, such as hysterosalpingogram films, semen analysis report, basal body temperature chart, previous laparoscopy test results. The IVF specialist Dr Neelam Bhise will counsel the couple about the program and some further investigations may be necessary to establish the chances of success. The woman may have to be scheduled for a screening laparoscopy, hysteroscopy, and ultrasonography, if needed to assess the pelvic anatomy and accessibility of the ovaries for egg retrieval."
                },
                {
                    title: "2. Hormonal Stimulation:",
                    para: "The treatment start from the previous cycle and stimulation begins in early phase of next cycle, so as to get higher number of eggs. The medications are meticulously calibrated according to patient's need and response."
                },
                {
                    title: "3. Monitoring the maturity of the Eggs:",
                    para: "Blood tests are drawn frequently from the onset of stimulation to determine the progress of stimulated ovaries. Serial Ultrasound examinations are conducted several times to visualize the developing follicles. The size of the follicles and result of the blood tests will determine the time of egg collection. Egg maturation is achieved by injections after the appropriate size of the follicle is reached."
                },
                {
                    title: "4. Egg retrieval:",
                    para: "Eggs are retrieved transvaginally by needle aspiration guided by ultrasonic imaging. This requires local anaesthesia / IV sedation / General anaesthesia. The eggs thus obtained are immediately placed in a cultured dish that contains a special nourishing fluid. The patient can be discharged from the IVF center soon after egg retrieval in 2-3 hours."
                },
                {
                    title: "5. Semen Specimen Collection:",
                    para: "Semen sample is collected preferably by masturbation at the time of collection of the eggs. There is separate and private facility for semen collection at ACME FERTILITY. There is a facility of freezing the semen sample before egg collection. We can freeze semen for longer duration also as per patient's requirements. Also it is preferable to do backup freezing, to tackle on time difficulties of procuring semen sample. The sperm cells are separated from the seminal fluid, mixed with the eggs and then are placed in incubator unit till such time, as the eggs are ready to be fertilized."
                },
                {
                    title: "6. Fertilization and Cleavage:",
                    para: "Fertilization is the process of sperm penetrating the egg. The egg being fertilized is now called an embryo. The growth and development of the embryo is monitored continuously. The cells of embryos then divide or multiply normally."
                },
                {
                    title: "7. Embryo Transfer:",
                    para: "Embryo transfer is done between day 2 to day 5 depending upon number and quality of embryos available. The embryos are placed into uterus using thin tube (catheter) through the mouth of the womb (Cervix). The procedure is done on outpatient basis. The patient can be discharged from IVF center soon after transfer procedure. When indicated, blastocyst stage transfer is offered. Generally 2 to 3 embryos are transferred and spare embryos, if any, are cryopreserved for future for the patient's own use with patient's consent."
                },
                {
                    title: "8. Luteal support:",
                    para: "In order to improve implantation of embryo in uterus, pure progesterone is given as luteal support in the form of vaginal pessaries, or gel or injections. Supportive medications to increase the blood circulation and endometrial stabilization are also started. The medications are tolerated by patients comfortably."
                },
                {
                    title: "9. Blood Tests:",
                    para: "Blood test for beta HCG titer is performed to confirm pregnancy approximately 12 days following embryo transfer."
                },
                {
                    title: "Assisted Laboratory Procedures",
                    para: "When beginning an IVF cycle, the ultimate goal of our team is to enable the patient to take home a healthy baby while minimizing the risk of multiples. New advances in laboratory techniques have made it possible to achieve this. Assisted reproductive technologies also include Intracytoplasmic sperm injection (ICSI), Blastocyst culture, assisted hatching and cryopreservation."
                },
                
            ]
        }
    },

    PRP: {
        AllDData:[
            {
                title: "Material and methods",
                para: "20 women with documented thin endometrium and/or with asherman syndrome were selected. Selection criteria used was faiure of endometrium to grow beyond 6mm in natural as well as HRT cycles inspite of supplementation with heparin, aspirin and sildenafil (1) and /or pts. With hysteroscopic finding of asherman's syndrome. The patients were recruited for frozen embryo transfer cycles. In addition to HRT, intrauterine infusion of PRP was performed. We stimulated the endometrium with incremental doses of estradiol. Endometrial preparation days ranged from 15 to 30 days. Decision of PRP was taken when endometrial thickness did not respond over 5 days of incremental esttradiol."
            },
            {
                title: "PRP preparation",
                para: "PRP was prepared by autologous blood centrifugation. 0.5 to 1ml of PRP was infused in the uterine cavity. Progesterone support was started from next day. Day 3 ET were done. Result: Successful implantation was observed in _ pts. Biochemical (BHCG) was observed in __ pts. Out of __pts.Actually, over the past years a technique that was invented over 20 years ago as been recovered to better sperm samples the day they are to be used for artificial insemination or IVF. The method is called MACS (Magnetic Activated Cell Sorting)."
            },
            {
                title: "Discussion",
                para: "The major functions of platelets are preventing acute blood loss and repairing vascular walls and adjacent tissues after injury. During wound healing, platelets are activated and aggregate to release granules containing growth factors, such as TGF-β, PDGF, IGF, VEGF, EGF and FGF-2 [10,11], which stimulate the inflammatory cascade and healing process. PRP is collected from an autologous blood sample that has been enriched with platelets about 4-5 times more than circulating blood. PRP with a large amount of cytokines and growth factors can stimulate proliferation and regeneration. (12, 13-16) PRP is now a days widely applied in clinical scenarios even in mucous tissues such as eye (17) and mouth (18 )to improve tissue degeneration. This study was to see the effectiveness of PRP in regenerating endometrium in patients with thin endometrium and/or asherman's syndrome. In this study, 6 patients had thin endometrium which didn't respond to estrogen and four patients had partial asherman's syndrome. After PRP, endometrial thickness became satisfactory in all patients and 8 out of 10 patients became pregnant after embryo transfer."
            },
        ]
    },

    IUI: {
        first: {
            AllDData:[
                {
                    title: 'Indications for IUI',
                    para: 'It has been universally observed that whenever IUI is combined with induction of ovulation or controlled ovarian stimulation, the success rate in the form of pregnancy is improved. Depositing actively motile sperm free from debris, leucocytes, pus cells, and dead sperm has a significant reproductive advantage in fertilizing the released oocyte from the ovary, in the fallopian tube. During natural intercourse, semen is deposited in the vagina, motile sperm from the semen move towards fallopian tube. Out of around 100 million sperm from a "normal" man deposited in the vagina, only about 1 million sperm find their way to the upper portion of the uterine cavity and only few hundred enter the tube where fertilization occurs. In IUI, 5-10 million motile sperm are deposited at the top of the uterine cavity near the opening of the tubes thus significantly increasing the chances of healthy sperm reaching the mature oocyte.'
                }
            ],
        },
        second: {
            AllDData1:[
                {
                    title: 'Preparations',
                    para: 'Artificial insemination can use sperm from a partner or donor. Sperm is obtained through masturbation or special condoms. Women track ovulation using temperature, mucus changes, or tests. For intrauterine insemination (IUI), sperm is washed, concentrated, and warmed. Donor sperm is frozen and quarantined for six months, with donors tested for diseases. Private donor sperm is usually fresh and not quarantined.'
                }
            ]
        },
        third: {
            AllDData2:[
                {
                    title: 'Procedure',
                    para: 'Artificial insemination involves introducing semen, whether fresh or thawed, into the womans vagina or uterus, often using a catheter to place "washed" semen directly into the uterus to reduce cramping risks. Pregnancy achieved through this method is comparable to natural conception, though there may be a slightly increased chance of multiple births with stimulated cycles. Optimal conditions include the woman being under 30, the man having high sperm count, and the presence of mature follicles and sufficient estrogen levels during the cycle. Higher pregnancy rates are associated with a short period of ejaculatory abstinence before the procedure.'
                }
            ]
        },
        four: {
            AllDData3:[
                {
                    title: '1. Intracervical insemination',
                    para: 'Intracervical insemination (ICI) is a simple method where fresh or thawed semen is injected into the cervix using a needleless syringe. The syringe is carefully inserted close to the cervix entrance, sometimes with a speculum. Timing is crucial, as fertilization window is short. Understanding the womans natural cycle is key. Advanced medical techniques can enhance conception chances.'
                },
                {
                    title: '2. Intrauterine tuboperitoneal insemination',
                    para: 'Intrauterine tuboperitoneal insemination (IUTPI) is insemination where both the uterus and fallopian tubes are filled with insemination fluid. The cervix is clamped to prevent leakage to the vagina, best achieved with the specially designed Double Nut Bivalve (DNB) speculum. IUTPI can be useful in unexplained infertility, mild or moderate male infertility, and mild or moderate endometriosis. In non-tubal sub fertility, fallopian tube sperm perfusion may be the preferred technique over intrauterine insemination.'
                },
                {
                    title: '3. Intratubal insemination',
                    para: 'IUI can furthermore be combined with intratubal insemination (ITI), into the Fallopian tube although this procedure is no longer generally regarded as having any beneficial effect compared with IUI.'
                },
                {
                    title: '4. Pregnancy rate',
                    para: 'Success rates or pregnancy rates for artificial insemination may be very misleading, since many factors including the age and health of the recipient have to be included to give a meaningful answer, e.g. definition of success and calculation of the total population. For couples with unexplained infertility, unstimulated IUI is no more effective than natural means of conception. The pregnancy rate also depends on the total sperm count, or, more specifically, the total motile sperm count (TMSC), used in a cycle. It increases with increasing TMSC, but only up to a certain count, when other factors become limiting to success. The summed pregnancy rate of two cycles using a TMSC of 5 million in each cycle is substantially higher than one single cycle using a TMSC of 10 million. However, although more cost-efficient, using a lower TMSC also increases the average time taken before getting pregnant.'
                },
                
            ]
        },
        
       
    },

    EmbryoTransfer: {
        AllDData:[
            {
                title: 'Ultrasonography',
                para: 'Ultrasonography is very important for studying the progress of ovaries, uterus. We here use trans vaginalultrasound, and ultrasound guided trans vaginal follicle aspiration. This oocyte retrieval technique gives better visualization, finer control, and less patient discomfort compared with other available methods. Obviating the need for laparoscopy decreased the number of personnel, time and procedure expense, reduced the risks of surgery and general anesthesia, and provided greater patient acceptance. IVF cases transitioned from 1-2 hours of hospital-based operating room time to 10-15 minute procedures that can be performed in an office setting.'
            },
            {
                title: 'Air purification',
                para: 'The environment in IVF center is one of most important issue in improving success rate. Perfumes, deodorants or any smelling substance is injurious to the growth of embryos. We at ACME FERTILITY clinic & IVF center use 3 different air purification machines which have 4 -5 different types of filters built in it to maintain clean, healthy and embryo friendly environment in IVF lab. Prevention is best treatment, we strictly do not allow any outside infection to enter in IVF lab. This is done by a planned process while entering in IVF lab. Hand wash is very religiously followed at our center.'
            },
            {
                title: 'ICSI',
                para: 'As IVF became more common place in the treatment of female infertility, male infertility remained a limiting factor to overall success. Conventional IVF was much less effective when semen parameters were below the reference values for concentration (oligozoospermia), motility (asthenozoospermia), and morphology (teratozoopermia), resulting in significantly lower fertilization rates and fewer embryos available for transfer. Furthermore, azoospermic males were completely devoid of treatment options. We use a novel procedure called Intracytoplasmic sperm injection (ICSI), where a single spermatozoon is microinjected into the oocyte after passage through the zona pellucida and the membranes of the oocyte. This procedure achieved fertilization rates of approximately 60% - 70% when using ejaculated sperm.'
            },
            {
                title: 'Co2 incubators',
                para: 'Co2 incubators are used for culturing embryos. They mimic the female reproductive environment for the embryo to develop and multiply till they are transferred to uterus. We at ACME FERTILITY clinic & IVF center use one the latest and advanced incubator with strict temperature and Co2 concentration control. Its calibration is done on regular basis, so as to give us better results.'
            },
            
        ]
    },

    PGS: {
        AllDData:[
            {
                title: 'Testing at five to six days',
                para: 'It is possible that instead of removing and testing one or two cells from a two - three day old embryo, some centres may allow the embryo to develop to five - six days, when there are 100-150 cells. More cells can be removed at this stage without compromising the viability of the embryo, possibly leading to a more accurate test. Alternatively some centres may test eggs for chromosomal abnormalities before they are used to create embryos. Polar bodies (small cells extruded by eggs as they mature) can be extracted and tested.'
            },
            {
                title: 'Comparative Genomic Hybridisation (CGH)',
                para: 'Clinics are now using a procedure called comparative genomic hybridisation (CGH) which allows centres to test for abnormalities in all 23 chromosomes. These abnormalities may or may not be of biological significance, but their presence will lower the chance of finding suitable embryos for transfer.'
            },
            {
                title: 'What are the risks of PGS?',
                para: 'Some of the risks involved in PGS treatment are similar to those for conventional IVF. Other problems unique to PGS treatment include: 1.some embryos may be damaged by the process of cell removal. 2.possibility that no embryos are suitable for transfer to the womb after PGS. It is important to understand that there is no guarantee against a miscarriage occurring even though PGS has been carried out prior to embryo transfer.'
            },
        ]
    },

    GamateDonars: {
        AllDData:[
            {
                title: 'Who is a suitable donor',
                para: 'Woman, who is healthy, preferably married and less than 35 years age, with proven fertility can become an oocyte donor.'
            },
            {
                title: 'Procedure',
                para: 'The process begins by synchronizing the menstrual cycles of the donor and recipient. The donor then undergoes a cycle of ovarian stimulation with the aim of producing as many eggs as possible. The recipient takes medication (Tab oestradiol valerate + pure progesterone) for 1-2 cycles prior to IVF when the formation of the endometrial lining is studied. When an adequate lining does form, the recipients are now ready to accept embryos. The cycles of the donor and recipient are then synchronized. The donor undergoes ovarian stimulation, using the GnRH Analog/FSH/HCG long protocol, Oocytes are recovered by transvaginal USG guided aspiration 36 hours after Inj. HCG, The recipient starts Inj. Gestone on the day after the donor receives HCG'
            },
          
        ]
    },

    SemenCrypto:{
        AllDData:[
            {
                title: 'The process',
                para: 'ust as each person is unique, so is the success of the freezing/thawing process for each sperm sample. For each semen sample we determine the semen volume, sperm count (concentration), and percent motility (number of sperm that swim). We use a special solution (cryoprotectant) to protect the sperm from freezing conditions and place it into distinctive containers (cryovials) for freezing. Depending on the size and the quality of the sample, we will use one or more vials per sample. (Each vial can hold up to 1.8 ml). Each vial is labeled with your name, the freeze date and a unique vial number. The vials are slowly cooled, then frozen and stored in our liquid nitrogen tanks. Following freezing of the first sample, a small volume of semen is thawed to determine how well the freezing process worked and the number of motile sperm remaining per vial.'
            },
            {
                title: 'How Cryopreservation is Performed',
                para: "After a semen sample has been collected, it is placed on a warming block maintained at 37°C, to liquefy. At this time, the semen sample is mixed in a 1:1 ratio with a freezing medium that allows the sperm to survive the freezing and storage process. The semen and freezing medium mixture is divided up into one milliliter portions and placed in special containers called cryovials. The freezing medium used contains cyroprotectants. Cryoprotectants are chemicals that help remove water from the cells being frozen. If the water is not removed from the sperm, ice crystals will form inside of the cell and break up it up, resulting in cell death. Semen samples are slowly frozen in liquid nitrogen vapors. Once the samples have been frozen in the liquid nitrogen vapors, they are placed in special containers where they are stored in the liquid nitrogen until they are needed. There is a considerable amount of variability, but each ejaculate typically yields from one to six vials. Each vial of a patient's semen sample is labeled with the patient's name, the date, and a unique number recorded on the side. This information will be used to catalog the semen sample into the storage facility."
            },
            {
                title: 'Success rates with frozen sperm',
                para: 'If you choose to have your sperm frozen, you can use your sperm at a later date to get pregnant. This would work either through intrauterine insemination (IUI) or in vitro fertilization (IVF). When we thaw the sperm sample, experts in our lab are able to analyze the motility (ability to move) and mobility of the sperm through a “test thaw.” If the sperm has been negatively affected by freezing/thawing, the rate of conception through IUI may be lower. When we identify poor sperm characteristics, or when very few frozen sperm samples are available, IVF may be a better choice. In general, the IVF success rate is not affected by using cryopreserved semen. In any event, when the time comes, you will always have the opportunity to discuss options with one of our specialists. We will help you determine the best options for achieving a pregnancy using your frozen samples.'
            },
            {
                title: 'The number of semen samples to freeze',
                para: "When it's time to decide how many samples to freeze, you will work with your doctor and discuss: Semen quality, Conception rates, Current technology, Anticipated sample need, Time constraints, Sperm cryopreservation doesn't ensure future reproductive success, but aims to preserve a reasonable chance of pregnancy. Recommended: freeze enough samples for at least four IUIs, each with 10-20 million motile sperm. Typically achieved with two samples. If not met, IVF is an option. Time constraints may limit multiple sample freezing. Lab provides cryopreservation data and recommends additional freezing if necessary."
            },
            {
                title: 'Length of time sperm samples may be frozen',
                para: 'As far as we know, the viability of cryopreserved sperm may be kept forever. This is due to the freezing technique and because the semen is stored at such a low temperature (-196 C). Samples stored more than 20 years have been used to create successful pregnancies and healthy babies.'
            },
            
          
        ]
    },

    EmbryoCrypto:{
        AllDData:[
            {
                title: 'The process',
                para: 'ust as each person is unique, so is the success of the freezing/thawing process for each sperm sample. For each semen sample we determine the semen volume, sperm count (concentration), and percent motility (number of sperm that swim). We use a special solution (cryoprotectant) to protect the sperm from freezing conditions and place it into distinctive containers (cryovials) for freezing. Depending on the size and the quality of the sample, we will use one or more vials per sample. (Each vial can hold up to 1.8 ml). Each vial is labeled with your name, the freeze date and a unique vial number. The vials are slowly cooled, then frozen and stored in our liquid nitrogen tanks. Following freezing of the first sample, a small volume of semen is thawed to determine how well the freezing process worked and the number of motile sperm remaining per vial.'
            },
            {
                title: 'How Cryopreservation is Performed',
                para: "After a semen sample has been collected, it is placed on a warming block maintained at 37°C, to liquefy. At this time, the semen sample is mixed in a 1:1 ratio with a freezing medium that allows the sperm to survive the freezing and storage process. The semen and freezing medium mixture is divided up into one milliliter portions and placed in special containers called cryovials. The freezing medium used contains cyroprotectants. Cryoprotectants are chemicals that help remove water from the cells being frozen. If the water is not removed from the sperm, ice crystals will form inside of the cell and break up it up, resulting in cell death. Semen samples are slowly frozen in liquid nitrogen vapors. Once the samples have been frozen in the liquid nitrogen vapors, they are placed in special containers where they are stored in the liquid nitrogen until they are needed. There is a considerable amount of variability, but each ejaculate typically yields from one to six vials. Each vial of a patient's semen sample is labeled with the patient's name, the date, and a unique number recorded on the side. This information will be used to catalog the semen sample into the storage facility."
            },
            {
                title: 'Success rates with frozen sperm',
                para: 'If you choose to have your sperm frozen, you can use your sperm at a later date to get pregnant. This would work either through intrauterine insemination (IUI) or in vitro fertilization (IVF). When we thaw the sperm sample, experts in our lab are able to analyze the motility (ability to move) and mobility of the sperm through a “test thaw.” If the sperm has been negatively affected by freezing/thawing, the rate of conception through IUI may be lower. When we identify poor sperm characteristics, or when very few frozen sperm samples are available, IVF may be a better choice. In general, the IVF success rate is not affected by using cryopreserved semen. In any event, when the time comes, you will always have the opportunity to discuss options with one of our specialists. We will help you determine the best options for achieving a pregnancy using your frozen samples.'
            },
            {
                title: 'The number of semen samples to freeze',
                para: "When it's time to decide how many samples to freeze, you will work with your doctor and discuss: Semen quality, Conception rates, Current technology, Anticipated sample need, Time constraints, Sperm cryopreservation doesn't ensure future reproductive success, but aims to preserve a reasonable chance of pregnancy. Recommended: freeze enough samples for at least four IUIs, each with 10-20 million motile sperm. Typically achieved with two samples. If not met, IVF is an option. Time constraints may limit multiple sample freezing. Lab provides cryopreservation data and recommends additional freezing if necessary."
            },
            {
                title: 'Length of time sperm samples may be frozen',
                para: 'As far as we know, the viability of cryopreserved sperm may be kept forever. This is due to the freezing technique and because the semen is stored at such a low temperature (-196 C). Samples stored more than 20 years have been used to create successful pregnancies and healthy babies.'
            },
            
          
        ]
    },

    ForeignPatientCare:{
        AllDData:[
            {
                title: 'Step to register as a patient with us',
                para: 'The first step to becoming a patient at ACME FERTILITY is to contact our International Patient Care Services Office. Our International Coordinators are fluent in many languages and can assist you in all aspects of your care. It would be helpful if you could call us on O22-2527 6006 / 022- 4972 6570 / 71(Time IST 10 am -6 pm), Any Time Mobile no. +91 7718 98 6006 / +91 8655 00 6565 Email us your needs along with your medical records to help@acmefertility.com or info@acmefertility.com Once we know about you and your needs it will help us to expedite the process.'
            }, 
            {
                title: '1. Submitting Medical Records',
                para: 'While submitting your medical records as said above, please make sure that your records are complete, and preferably in English, and include all relevant scans, x-rays and test results.'
            },
            {
                title: '2. Visas & Passports',
                para: 'International patients will need to obtain a visa (depending upon their home country) to enter India for medical treatment. ACME FERTILITY International Patient Care staffs can assist with the visas process, tickets and other travel arrangements and for any other information or assistance you may require, when you decide to seek a treatment at ACME FERTILITY clinic & IVF center.'
            },
            {
                title: '3. International Patient Care office',
                para: "The International Patient Care office is equipped with modern communication equipment like email, fax, 24-hour helpline and staffed with trained coordinators. This office affords easy access to patients across the globe and it puts at ease the patient's anxiety relating to his travel and stay."
            },
            {
                title: '4. Patients received upon arrival',
                para: 'From the moment the patient lands at Mumbai airport, he is virtually a member of our center. Our team will help you so that you are conveyed safely and comfortably to ACME FERTILITY . More ever our center is hardly 15 minutes distance from international airport.'
            },
            {
                title: '5. Accommodation',
                para: "Our International Patient Care team arranges a comfortable accommodation as per your need. We see to it that it is close to our center, so that you won't have problem in daily commuting to us."
            },
            {
                title: '6. Post Treatment',
                para: 'We facilitate a smooth and hassle-free transfer of you and your baby to your country, so that we create a bond of love which will keep us close for years together. "Patients must be able to trust doctors with their lives & well being. To justify that trust, we as professionals, have a duty to maintain a good standard of practice and care and show respect for human life."'
            },
        ]
    },

    SET:{
        AllDData:[
            {
                title: 'What is elective single embryo transfer (eSET)?',
                para: 'Elective single-embryo transfer (eSET) is a procedure in which one embryo, selected from a larger number of available embryos, is placed in the uterus or fallopian tube. The embryo selected for eSET might be from a previous IVF cycle (e.g., cryopreserved embryos (frozen)) or from the current fresh IVF cycle that yielded more than one embryo. The remaining embryos may be set aside for future use or cryopreservation. eSET helps women avoid several risks to their own health that are associated with carrying multiples. It also helps families achieve success while preventing some risks known to be associated with giving birth to twins or what is called "high order multiple births" (three or more children born at the same time). Infants born in multiple births are more often born early (preterm), are smaller (low birth weight) and experience more adverse health outcomes than singleton infants. There is consensus among experts that the desired outcome of ART is a healthy singleton infant.'
            }, 
            {
                title: 'Who is most suitable for single embryo transfer?',
                para: "Evidence from the medical literature suggests that the use of single embryo transfer does not reduce your chance of becoming pregnant by IVF, but will reduce your risk of having a twin pregnancy if you are: 1.Under the age of 37. 2.Undergoing your first or second IVF treatment (that is, you haven't had multiple IVF attempts). 3.Able to produce at least three good quality embryos on day 3. "
            }, 
           
        ]
    },

    LaserAssistedHatching:{
        AllDData:[
            {
                title: 'What are the risks of Assisted Hatching?',
                para: 'Current research suggests that this procedure is no more likely to cause an abnormality to the baby than IVF without Laser Assisted Hatching. The outer shell of the embryo is the only one being treated leaving the embryo unharmed.'
            }, 
           
        ]
    },

    OocyteFreezing:{
        AllDData: [
            {
                title: "1. Egg Freezing Process",
                para: "First, your fertility specialist may perform an assessment of the ovarian reserve to estimate the potential yield of oocytes prior to ovarian stimulation cycle. The assessment would include blood tests and pelvic ultrasound. This will also help to determine the necessary dose of medications. Ovarian stimulation is carried out in the same manner that is used with in vitro fertilization (IVF), using injectable hormonal medications. Following the stimulation, the oocytes and the surrounding fluid in the ovarian follicles are aspirated vaginally while under sedation. The maturity of the eggs is assessed under the microscope, and those that are mature are cryopreserved. Currently, vitrification is the method of choice for cryopreserving oocytes, and this is achieved by ultra-rapid cooling into liquid nitrogen where they can be stored."
            },
            {
                title: "2. How Will the Eggs be Used in the Future?",
                para: "When the woman is ready to use the frozen eggs to achieve pregnancy, these cryopreserved eggs are placed in warming solution and assessed. Those eggs that survived the freezing process are fertilized with intracytoplasmic sperm injection (ICSI), where a single sperm is injected directly into the egg, and the fertilized eggs will grow in culture until the embryo(s) are ready to be transferred into the uterus to achieve pregnancy, typically 3-5 days after fertilization."
            },
            {
                title: "3. What are the Chances of a Successful Pregnancy Using Frozen-thawed Oocytes?",
                para: "Clinical pregnancy rates have been estimated between 4-12% per oocyte. But since egg freezing is relatively new, more data will be needed to have a better idea on these success rates. In general, the two most important factors in determining the probability of a live birth are the woman’s age at the time of egg freezing and the number of available eggs."
            },
            {
                title: "4. Are There Effects on the Offspring Conceived from Frozen-thawed Oocytes?",
                para: "Available data comparing births resulting from previously frozen oocytes with those from fresh oocytes have not shown an increased risk of congenital anomalies. More long-term data, however, will be needed to further assess these risks."
            },
            {
                title: "5. What are the Risks Associated with the Oocyte Cryopreservation Procedure?",
                para: "Risks are similar to those associated with ovarian stimulation for IVF, which include small risks of ovarian hyperstimulation syndrome (enlargement of the ovaries and fluid accumulation in the pelvis and abdomen), infection, and bleeding related to the egg retrieval procedure."
            },
            {
                title: "6. How Long Can the Eggs be Stored?",
                para: "Storing the eggs for longer durations does not appear to have negative effects. However, data are only available for up to 4 years of storage. It must be remembered that older maternal age when carrying a pregnancy is associated with higher risks of pregnancy complications, such as high blood pressure, diabetes, and cesarean section. Most clinics have an upper age limit on when these gametes can be used to achieve pregnancy."
            },
        ]
    }
    

}



export default AllDetailsData