import React from 'react'
import TopContext from './Common/TopContext'
import TopContextData from './Common/TopContextData'
import AllDetails from '../Common/Services/AllDetails';
import AllDetailsData from '../Common/Services/AllDetailsData';
import SDevide from './Common/SDevide';
import SDevideData from './Common/SDevideData';

const IUI = () => {
    const {greenHeading, head, video, pOne} = TopContextData.IUI;
    const {AllDData} = AllDetailsData.IUI.first;
    const {sdHeading, sdImg, SDdata} = SDevideData.IUI.male;
    const {sdHeading1, sdImg1, SDdata1} = SDevideData.IUI.female;
    const {sdHeading2, sdImg2, SDdata2} = SDevideData.IUI.Preparation;
    const {AllDData1} = AllDetailsData.IUI.second;
    const {AllDData2} = AllDetailsData.IUI.third;
    const {AllDData3} = AllDetailsData.IUI.four;


  return ( 
    <>
    <TopContext greenHeading={greenHeading} head={head} video={video} pOne={pOne} />
    <AllDetails AllDData={AllDData} />
    <SDevide sdHeading={sdHeading} sdImg={sdImg} SDdata={SDdata} />
    <SDevide sdHeading={sdHeading1} sdImg={sdImg1} SDdata={SDdata1} />
    <AllDetails AllDData={AllDData1} />
    <SDevide sdHeading={sdHeading2} sdImg={sdImg2} SDdata={SDdata2} />
    <AllDetails AllDData={AllDData2}/>

    <div class="blog-card test-details-card"><h4>Other Techniques</h4></div>
    <AllDetails AllDData={AllDData3}/>

    
    </>
  )
}

export default IUI