import React from 'react'
import { Link } from 'react-router-dom'

const RightHandSide = () => {
  return (
    <>
    <div className="details-info">
          <h5>Success Rate</h5>
          <ul className="categorie-list">
            <li>
              <i className="bx bxs-copy" />
              <Link>IVF</Link>
              <span>60+</span>
            </li>
            <li>
              <i className="bx bxs-copy" />
              <Link>ICSI</Link>
              <span>72+</span>
            </li>
            <li>
              <i className="bx bxs-copy" />
              <Link>IUI</Link>
              <span>35+</span>
            </li>
            <li>
              <i className="bx bxs-copy" />
              <Link>Egg Donation</Link>
              <span>75+</span>
            </li>
            <li>
              <i className="bx bxs-copy" />
              <Link>Embryo Donation</Link>
              <span>76+</span>
            </li>
            <li>
              <i className="bx bxs-copy" />
              <Link>Surrogacy</Link>
              <span>80+</span>
            </li>
           
          </ul>
        </div>
        <div className="details-info">
          <h5>Programe</h5>
          <div className="tag">
            <Link to="/Programe">IVF & ICSI</Link>
            <Link to="/Programe">PRP</Link>
            <Link to="/Programe">IUI</Link>
            <Link to="/Programe">Embryo Transfer</Link>
            <Link to="/Programe">Assisted Heching</Link>
            <Link to="/Programe">PGS</Link>
            <Link to="/Programe">MACS</Link>
            <Link to="/Programe">Gamate Donars</Link>
            <Link to="/Programe">Semen Cryptopreservation</Link>
            <Link to="/Programe">Embryo Cryptopreservation</Link>
            <Link to="/Programe">Foreign Patient Care</Link>
            <Link to="/Programe">Single Embryo Transfer</Link>
            <Link to="/Programe">Laser Assisted Hatching</Link>
            <Link to="/Programe">Oocyte Freezing</Link>
            <Link to="/Programe">PISCI</Link>
          </div>
        </div>
    </>
  )
}

export default RightHandSide