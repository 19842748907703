import React, { useState } from "react";
import { motion } from "framer-motion";
import img from "../../Assets/images/shapes/newslatter-1.png";
import docShape from "../../Assets/images/shapes/doctor-shape-2.png";
import shapeThree from "../../Assets/images/shapes/newsletter-3.png";
import shapeFive from "../../Assets/images/shapes/newsletter-5.png";
import "../../Style.scss";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const Counter2 = () => {
  const [countOn, setCountOn] = useState(false);

  return (
    <div className="funfacts-area bg-color pt-5 pb-70 bg-002B55">
      <div className="funfacts-inner">
        <div className="container">
          <h4 className="success">Success Rates</h4>

          <ScrollTrigger
            onEnter={() => {
              setCountOn(true);
            }}
            onExit={() => {
              setCountOn(false);
            }}
          >
            <div className="row">
              <div className="col-lg-2 col-md-4 col-12 col-sm-4">
                <motion.div
                  initial={{ y: 50, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{
                    y: { type: "spring", stiffness: 60 },
                    opacity: { duration: 0.2 },
                    ease: "easeIn",
                    duration: 1,
                  }}
                  className="single-funfacts-box white-color"
                >
                  <h3>
                    <span className="odometer">
                      {countOn && (
                        <CountUp start={0} end={60} duration={2} delay={0} />
                      )}
                    </span>
                    <span className="sign">+</span>
                  </h3>
                  <p>IVF</p>
                </motion.div>
              </div>

              <div className="col-lg-2 col-md-4 col-12 col-sm-4">
                <motion.div
                  initial={{ y: 50, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{
                    y: { type: "spring", stiffness: 60 },
                    opacity: { duration: 0.2 },
                    ease: "easeIn",
                    duration: 1,
                  }}
                  className="single-funfacts-box white-color"
                >
                  <h3>
                    <span className="odometer">
                      {countOn && (
                        <CountUp start={0} end={72} duration={2} delay={0} />
                      )}
                    </span>
                    <span className="sign">+</span>
                  </h3>
                  <p>ICSI</p>
                </motion.div>
              </div>

              <div className="col-lg-2 col-md-4 col-12 col-sm-4">
                <motion.div
                  initial={{ y: 50, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{
                    y: { type: "spring", stiffness: 60 },
                    opacity: { duration: 0.2 },
                    ease: "easeIn",
                    duration: 1,
                  }}
                  className="single-funfacts-box white-color"
                >
                  <h3>
                    <span className="odometer">
                      {countOn && (
                        <CountUp start={0} end={75} duration={2} delay={0} />
                      )}
                    </span>
                    <span className="sign">+</span>
                  </h3>
                  <p>Egg Donation</p>
                </motion.div>
              </div>

              <div className="col-lg-2 col-md-4 col-12 col-sm-4">
                <motion.div
                  initial={{ y: 50, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{
                    y: { type: "spring", stiffness: 60 },
                    opacity: { duration: 0.2 },
                    ease: "easeIn",
                    duration: 1,
                  }}
                  className="single-funfacts-box white-color"
                >
                  <h3>
                    <span className="odometer">
                      {countOn && (
                        <CountUp start={0} end={76} duration={2} delay={0} />
                      )}
                    </span>
                    <span className="sign">+</span>
                  </h3>
                  <p>Egg Donation</p>
                </motion.div>
              </div>

              <div className="col-lg-2 col-md-4 col-12 col-sm-4">
                <motion.div
                  initial={{ y: 50, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{
                    y: { type: "spring", stiffness: 60 },
                    opacity: { duration: 0.2 },
                    ease: "easeIn",
                    duration: 1,
                  }}
                  className="single-funfacts-box white-color"
                >
                  <h3>
                    <span className="odometer">
                      {countOn && (
                        <CountUp start={0} end={80} duration={2} delay={0} />
                      )}
                    </span>
                    <span className="sign">+</span>
                  </h3>
                  <p>Surrogacy</p>
                </motion.div>
              </div>

              <div className="col-lg-2 col-md-4 col-12 col-sm-4">
                <motion.div
                  initial={{ y: 50, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{
                    y: { type: "spring", stiffness: 60 },
                    opacity: { duration: 0.2 },
                    ease: "easeIn",
                    duration: 1,
                  }}
                  className="single-funfacts-box white-color"
                >
                  <h3>
                    <span className="odometer">
                      {countOn && (
                        <CountUp start={0} end={35} duration={2} delay={0} />
                      )}
                    </span>
                    <span className="sign">+</span>
                  </h3>
                  <p>IUI</p>
                </motion.div>
              </div>
            </div>
          </ScrollTrigger>
        </div>
      </div>
      <div className="funfact-shape">
        <img src={img} className="fun-shape-1" alt="1" />
        <img src={docShape} className="fun-shape-2" alt="2" />
        <img src={shapeThree} className="fun-shape-3" alt="3" />
        <img src={shapeFive} className="fun-shape-4" alt="4" />
        <img src={shapeFive} className="fun-shape-5" alt="5" />
      </div>
    </div>
  );
};

export default Counter2;
