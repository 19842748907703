import React from 'react';
import location from '../../Assets/images/icons/location.svg';
import time from '../../Assets/images/icons/time.svg';
import phone from '../../Assets/images/icons/phone.svg'
import { Link } from 'react-router-dom';

import doctor from "../../Assets/images/popular/DrNeelamMaam.png"

const DoctorProfile = () => {
  return (
    <div className="doctor-profile-area pt-100 pb-70">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 col-12">
                    <div className="doctor-profile-widget">
                        <div className="search-card">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-6 col-8 doc_pic">
                                    <img src={doctor} className='rounded' alt="1"/>
                                </div>
                                <div className="col-lg-5 col-md-5 col-sm-6">
                                    <div className="search-content">
                                        <h4>Dr. Neelam Bhise</h4>
                                        <p className="profile-p">Fertility Specialist and Endoscopic Surgeon</p>
                                        <span className="profile-span">20 year experience overall</span>
                                        <ul className="doctor-self">
                                            <li>
                                                <img src={location} alt="icon" />
                                                402, Signature Business Park, Chembur, Mumbai
                                            </li>
                                            <li>
                                                <img src={time} alt="icon" />
                                                10 AM - 1 PM
                                            </li>
                                            <li>
                                                <img src={phone} alt="icon" />
                                                <Link> +91 8655 00 6567</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 d-sm-none">
                                    <div className="search-content search-content2">
                                        <ul className="star-list">
                                            <li><i className="bx bxs-star"></i></li>
                                            <li><i className="bx bxs-star"></i></li>
                                            <li><i className="bx bxs-star"></i></li>
                                            <li><i className="bx bxs-star"></i></li>
                                            <li><i className="bx bxs-star"></i></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="doctor-profile-tab">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                                        Overview
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false" tabindex="-1">
                                        Experience
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false" tabindex="-1">
                                        Location
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="map-tab" data-bs-toggle="tab" data-bs-target="#contact2" type="button" role="tab" aria-controls="contact2" aria-selected="false" tabindex="-1">
                                        Publications
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade active show" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div className="test-details-card">
                                        <div className="all-details">
                                            <h3 className="tab-tag">About Dr. Neelam Bhise</h3>
                                            <p className="frist-p">Dr. Neelam Bhise is the scientific director of ACME FERTILITY (Unit of Acme Healthcare center PVT LTD). She is a Fertility consultant and Reproductive Endocrinologist.She has completed her Masters (MS) from TERTIARY HOSPITAL MUMBAI. She holds fellowship in Reproductive Endocrinology from NATIONAL UNIVERSITY HOSPITAL, SINGAPORE.</p>

                                            <p>Dr Neelam's breakthrough work involves treatment for repeated IVF failures, due to thin endometrium. She has presented papers on Platelet rich Plasma (PRP) therapies with 90% success rate in cases with previous failed IVF cycles. She has to her credit, The First Elderly Women from Mauritius, who conceived at the age of 60, with PRP therapy, and delivered a healthy baby.</p>

                                            <p>She is the recipient of many National and International Awards. She has treated many international patients from all over the world, esp. UK, Africa, Singapore, USA for their fertility issues and have got successful pregnancies.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <div className="experience">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="overall-details">
                                                    <h3 style={{color: "#0060bd"}}>Experience</h3>
                                                    <p>On academic front, DrNeelam Bhise is the past Assistant professor in Seth G S Medical College & KEM HOSPITAL, MUMBAI. She has also worked as Clinical associate in Breach candy hospital, Mumbai. She was the Consultant Gynec Surgeon at Elizabeth hospital, Malabar hill, Mumbai</p>

                                                    <p className='pb-4'>Dr. Neelam Bhise at Acme Fertility Hospital is a leading expert in reproductive medicine, offering a comprehensive range of fertility treatments tailored to each patient's needs. The hospital provides advanced In Vitro Fertilization (IVF) procedures, with a focus on maximizing success rates through personalized care. For couples facing male infertility issues, Intracytoplasmic Sperm Injection (ICSI) is available, ensuring the best chances for conception. Additionally, Acme Fertility Hospital supports patients with Egg Donation and Embryo Donation programs, offering hope to those with unique fertility challenges. For individuals considering alternative paths to parenthood, the hospital's Surrogacy program is designed with ethical and compassionate practices. Intrauterine Insemination (IUI) is also offered as a less invasive option for suitable candidates. Under the guidance of Dr. Bhise, Acme Fertility Hospital stands out as a beacon of hope and expertise in the field of fertility treatments.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                    <div className="test-details-card">
                                        <div className="all-details">
                                            <h4>Location</h4>
                                            <div id="panel" className="map-panel4 clinic-map">
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.0805152883077!2d72.89335401012818!3d19.060197282066603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c92322b10b53%3A0x52f9dda6d7a96d3f!2sAcme%20Fertility!5e0!3m2!1sen!2sin!4v1720606212135!5m2!1sen!2sin" title='googleMap' width="100%" height="415px" ></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="contact2" role="tabpanel" aria-labelledby="map-tab">
                                    <div className="test-details-card">
                                    <div className="all-details">
                                <h4>Publications</h4>
                                <p>On social front, Dr. Neelam Bhise has been actively engaged in several social health awareness educational programs. DrNeelam's passion has always been to focus on Polycystic ovarian Syndrome (PCOS) for which she has conducted many Adolescent educational programs. Dr. Neelam also conducts ART clinician's courses for medical practitioners and gynecologists. She is an active member of INTERNATIONAL FEDERATION OF FERTILITY SOCIETES (IFFS) and FERTILITY EDUCATION & MEDICAL MANAGEMENT (FEMM) since 2015.</p>
                                <ul className="award-icon">
                                    <li>
                                        <i className="bx bx-log-in-circle"></i>
                                        She has been Editor & Co Author of book on "Newer Drugs in OBS & GYN"
                                    </li>
                                    <li>
                                        <i className="bx bx-log-in-circle"></i>
                                        "A Rare case of Malignant Mixed Mullein Tumor." Bombay Hospital Journal.
                                    </li>
                                    <li>
                                        <i className="bx bx-log-in-circle"></i>
                                        "Hepatobiliary disorders in Pregnancy maternal and neonatal" outcome Bombay Hospital Journal
                                    </li>
                                    <li>
                                        <i className="bx bx-log-in-circle"></i>
                                        "Perforating choriocarcinoma with implantation metastasis to retro peritoneum." Bombay Hospital Journal
                                    </li>
                                    <li>
                                        <i className="bx bx-log-in-circle"></i>
                                        "In utero distortion of T shaped intrauterine device" Bombay Hospital Journal
                                    </li>
                                    <li>
                                        <i className="bx bx-log-in-circle"></i>
                                        'Study of jaundice in Pregnancy in a tertiary care institute in India' Bombay hospital journal.
                                    </li>
                                </ul>
                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="test-details-card">
                            <div className="all-details">
                                <h4>Educational Background</h4>
                                <ul className="circle-icon">
                                    <li>
                                        <i className="bx bxs-circle"></i>
                                        MS (OGY&GYN)B.Y.L. NAIR TERTIARY CARE HOPSITAL MUMBAI
                                    </li>
                                    <li>
                                        <i className="bx bxs-circle"></i>
                                        Fellowship in Reproductive Endocrinology (NATIONAL UNIVERSITY HOSPITAL, SINGAPORE 2017)
                                    </li>
                                   
                                </ul>
                            </div>
                            <div className="all-details">
                                <h4>Awards</h4>
                                <p>She is the recipient of many National and International Awards.</p>
                                <ul className="award-icon">
                                    <li>
                                        <i className="bx bx-log-in-circle"></i>
                                        Dr Neelam Bhise has been awarded with the India's Most promising and emerging IVF specialist Award at the hands of the Cabinet minister Mr Jagat Prakash Nadda in Aug 2017.
                                    </li>
                                    <li>
                                        <i className="bx bx-log-in-circle"></i>
                                        She has also been awarded the “50 outstanding Women Health care Award 2017” representatives amongst 130 countries, at world congress on medical tourism FEB 2017.
                                    </li>
                                    <li>
                                        <i className="bx bx-log-in-circle"></i>
                                        Received “MOGS -N A Purandare award” for best paper presentation on Intravaginal Sling Surgeries. 2010
                                    </li>
                                    <li>
                                        <i className="bx bx-log-in-circle"></i>
                                        “PRE IVF HYSTEOSCOPY” presentation at IFFS 2016
                                    </li>
                                    <li>
                                        <i className="bx bx-log-in-circle"></i>
                                        Best Paper presentation award on Jaundice in Pregnancy” YUVA FOGSI NAGPUR 2009
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="details-info">
                        <h5>Book Appointment</h5>
                        <div className="form-group">
                            <label>
                                {/* <img src="assets/images/icons/calendar-svgrepo-com.svg" alt="icon" /> */}
                            </label>
                            <input type="date" className="form-control hasDatepicker ps-3" id="datepicker"/>
                        </div>
                        <div className="search-selector">
                            <select className="form-select" aria-label="Default select example">
                                <option selected="">Time</option>
                                <option value="2">10:00-2:00pm</option>
                                <option value="2">6:00-12:00pm</option>
                                <option value="2">8:00-3:00pm</option>
                                <option value="2">11:00-6:00pm</option>
                            </select>
                        </div>
                        <div className="search-selector">
                            <select className="form-select" aria-label="Default select example">
                                <option selected="">Choose Your Doctor</option>
                                <option value="2">Dr. Neelam Bhise</option>
                            </select>
                        </div>
                        <button type="submit" className="common-btn style1">Book Appointment</button>
                    </div>

                    <div className="details-info">
                        <h6>Opening Hours</h6>
                        <ul className="opening-info">
                            <li>
                                Monday <span>10:00 am - 6:00 pm</span>
                            </li>
                            <li>
                                Tuesday <span>10:00 am - 6:00 pm</span>
                            </li>
                            <li>
                                Wednesday <span>10:00 am - 6:00 pm</span>
                            </li>
                            <li>
                                Thursday <span>10:00 am - 6:00 pm</span>
                            </li>
                            <li>
                                Friday <span>10:00 am - 6:00 pm</span>
                            </li>
                            <li>
                                Saturday <span>10:00 am - 6:00 pm</span>
                            </li>
                            <li>
                                Sunday <span>Close</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default DoctorProfile