import React from 'react'
import TopContext from './Common/TopContext'
import TopContextData from './Common/TopContextData'
import SDevide from './Common/SDevide';
import SDevideData from './Common/SDevideData';
import AllDetails from '../Common/Services/AllDetails';
import AllDetailsData from '../Common/Services/AllDetailsData';

const OocyteFreezing = () => {
    const {greenHeading, head, video, pOne} = TopContextData.OocyteFreezing;
    const {sdHeading, sdImg, SDdata} = SDevideData.OocyteFreezing;
    const {AllDData} = AllDetailsData.OocyteFreezing;
  return (
    <>
    <TopContext greenHeading={greenHeading} head={head} video={video} pOne={pOne} />
    <SDevide sdHeading={sdHeading} sdImg={sdImg} SDdata={SDdata} />
    <div class="blog-card test-details-card"><h4>How is Egg Freezing Done?</h4></div>
    <AllDetails AllDData={AllDData} />
    </>
  )
}

export default OocyteFreezing