import React from "react";
import IVF_ICSI from "./IVF_ICSI";
import PRP from "./PRP";
import IUI from "./IUI";
import EmbryoTransfer from "./EmbryoTransfer";
import AssistedHeching from "./AssistedHeching";
import PGS from "./PGS";
import MACS from "./MACS";
import GamateDonars from "./GamateDonars";
import SemenCrypto from "./SemenCrypto";
import EmbryoCrypto from "./EmbryoCrypto";
import ForeignPatientCare from "./ForeignPatientCare";
import SET from "./SET";
import LaserAssistedHatching from "./LaserAssistedHatching";
import OocyteFreezing from "./OocyteFreezing";
import PICSI from "./PICSI";

const ProgrameTabPage = () => {
  return (
    <div className="featured-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span>Leading Care</span>
          <h2>All Programe</h2>
        </div>
        <div className="featured-tab-area">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                IVF & ICSI
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#tech"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                PRP
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="one1"
                data-bs-toggle="tab"
                data-bs-target="#one"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                IUI
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="two2"
                data-bs-toggle="tab"
                data-bs-target="#two"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Embryo Transfer
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="three3"
                data-bs-toggle="tab"
                data-bs-target="#three"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Assisted Heching
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="four4"
                data-bs-toggle="tab"
                data-bs-target="#four"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                PGS
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="five5"
                data-bs-toggle="tab"
                data-bs-target="#five"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                MACS
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="six6"
                data-bs-toggle="tab"
                data-bs-target="#six"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Gamate Donars
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="seven7"
                data-bs-toggle="tab"
                data-bs-target="#seven"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Semen Cryptopreservation
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="eight8"
                data-bs-toggle="tab"
                data-bs-target="#eight"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Embryo Cryptopreservation
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="nine9"
                data-bs-toggle="tab"
                data-bs-target="#nine"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Foreign Patient Care
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="ten10"
                data-bs-toggle="tab"
                data-bs-target="#ten"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Single Embryo Transfer
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="eleven11"
                data-bs-toggle="tab"
                data-bs-target="#eleven"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Laser Assisted Hatching
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="twevel12"
                data-bs-toggle="tab"
                data-bs-target="#twevel"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Oocyte Freezing
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="thirteen13"
                data-bs-toggle="tab"
                data-bs-target="#thirteen"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                PICSI
              </button>
            </li>
          </ul>

          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <IVF_ICSI />
            </div>

            <div
              className="tab-pane fade"
              id="tech"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <PRP />
            </div>

            <div
              className="tab-pane fade"
              id="one"
              role="tabpanel"
              aria-labelledby="one1"
            >
              <IUI />
            </div>

            <div
              className="tab-pane fade"
              id="two"
              role="tabpanel"
              aria-labelledby="two2"
            >
              <EmbryoTransfer />
            </div>

            <div
              className="tab-pane fade"
              id="three"
              role="tabpanel"
              aria-labelledby="three3"
            >
              <AssistedHeching />
            </div>

            <div
              className="tab-pane fade"
              id="four"
              role="tabpanel"
              aria-labelledby="four4"
            >
              <PGS />
            </div>

            <div
              className="tab-pane fade"
              id="five"
              role="tabpanel"
              aria-labelledby="five5"
            >
              <MACS />
            </div>

            <div
              className="tab-pane fade"
              id="six"
              role="tabpanel"
              aria-labelledby="six6"
            >
              <GamateDonars />
            </div>

            <div
              className="tab-pane fade"
              id="seven"
              role="tabpanel"
              aria-labelledby="seven7"
            >
              <SemenCrypto />
            </div>

            <div
              className="tab-pane fade"
              id="eight"
              role="tabpanel"
              aria-labelledby="eight8"
            >
              <EmbryoCrypto />
            </div>

            <div
              className="tab-pane fade"
              id="nine"
              role="tabpanel"
              aria-labelledby="nine9"
            >
              <ForeignPatientCare />
            </div>

            <div
              className="tab-pane fade"
              id="ten"
              role="tabpanel"
              aria-labelledby="ten10"
            >
              <SET />
            </div>

            <div
              className="tab-pane fade"
              id="eleven"
              role="tabpanel"
              aria-labelledby="eleven11"
            >
              <LaserAssistedHatching />
            </div>

            <div
              className="tab-pane fade"
              id="twevel"
              role="tabpanel"
              aria-labelledby="twevel12"
            >
              <OocyteFreezing />
            </div>

            <div
              className="tab-pane fade"
              id="thirteen"
              role="tabpanel"
              aria-labelledby="thirteen13"
            >
              <PICSI />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgrameTabPage;
